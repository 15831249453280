import { create } from "zustand";
import { devtools } from "zustand/middleware";
export var PassTab;
(function(PassTab) {
    PassTab[PassTab["USETICKET"] = 0] = "USETICKET";
    PassTab[PassTab["BUYSINGLE"] = 1] = "BUYSINGLE";
})(PassTab || (PassTab = {}));
export const useMembershipStore = create()(devtools((set, get)=>({
        currentTab: PassTab.BUYSINGLE,
        selectedPass: null,
        isShowNotAvailablePass: false,
        tickets: [],
        solvook: [],
        handoutCoupons: [],
        isCouponDialogOpen: false,
        setState: (key, param)=>{
            set((state)=>({
                    ...state,
                    [key]: param
                }));
        },
        reset: ()=>{
            set((state)=>({
                    ...state,
                    currentTab: PassTab.BUYSINGLE,
                    isShowNotAvailablePass: false
                }));
        }
    })));
