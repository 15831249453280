import { _ as _tagged_template_literal } from "@swc/helpers/_/_tagged_template_literal";
function _templateObject() {
    const data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  height: auto;\n  padding: 0 !important;\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
import { Button } from "antd";
import styled from "styled-components";
const NoPadButton = styled(Button).withConfig({
    componentId: "sc-664f0d5b-0"
})(_templateObject());
export default NoPadButton;
