import { _ as _tagged_template_literal } from "@swc/helpers/_/_tagged_template_literal";
function _templateObject() {
    const data = _tagged_template_literal([
        "\n  position: sticky;\n  background-color: #fff;\n  border-bottom: solid 1px #f3f3f3;\n  left: 0;\n  right: 0;\n  top: 0;\n  z-index: 800;\n  @media (max-width: 768px) {\n    border-bottom: none;\n  }\n\n  .inner {\n    max-width: 1200px;\n    position: relative;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    //gap: 40px;\n    padding: 12px 0;\n    height: ",
        "px;\n    @media (max-width: 1200px) {\n      padding: 0 32px;\n    }\n    @media (max-width: 768px) {\n      /* flex-direction: column; */\n      align-items: center;\n      gap: 12px;\n      padding: 12px 16px;\n      height: 61px;\n    }\n  }\n\n  .logo {\n    /* margin: 0 20px 0 0; */\n    display: flex;\n    gap: 20px;\n    margin: 0;\n    align-items: center;\n    /* width: 270px; */\n    //flex: 1;\n\n    a {\n      display: block;\n      /* padding: 8px; */\n      display: flex;\n\n      img {\n        display: block;\n      }\n    }\n\n    .mobile-menu-icon {\n      all: unset;\n      display: none;\n      cursor: pointer;\n    }\n\n    @media (max-width: 1200px) {\n      width: unset;\n      .mobile-menu-icon {\n        display: block;\n      }\n    }\n    @media (max-width: 768px) {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      width: 100%;\n      img {\n        height: 24px;\n        width: auto;\n      }\n\n      svg {\n        height: 24px;\n        width: 96px;\n      }\n\n      .mobile-menu-icon {\n        display: block;\n\n        p {\n          display: none;\n        }\n      }\n\n      hr {\n        display: none;\n      }\n    }\n  }\n\n  .search-area {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 24px;\n\n    &.hide {\n      display: none;\n    }\n\n    @media (max-width: 1200px) {\n      width: 100%;\n      order: 4;\n\n      .category {\n        display: none;\n      }\n    }\n    @media (max-width: 768px) {\n    }\n\n    .category {\n      width: 84px;\n      cursor: pointer;\n\n      a {\n        display: block;\n        font-size: 16px;\n        font-weight: bold;\n        white-space: nowrap;\n        transition: opacity 0.2s;\n        z-index: 1;\n\n        &,\n        span svg {\n          color: ",
        ";\n          transition: color 0.2s;\n        }\n\n        span {\n          display: inline-block;\n          margin-right: 4px;\n        }\n\n        body:has(&) {\n          .ant-dropdown {\n            &.category_dropdown {\n              padding-top: 16px;\n\n              .ant-dropdown-menu {\n                width: 160px;\n\n                .ant-dropdown-menu-item {\n                  height: 40px;\n\n                  @media (hover: hover) {\n                    &:hover {\n                      color: ",
        ";\n                      background-color: #f5f8fb;\n                    }\n                  }\n                }\n              }\n            }\n          }\n\n          .ant-select-dropdown.search_type_dropdown {\n            padding: 4px 0;\n\n            .ant-select-item {\n              .ant-select-item-option-content {\n                line-height: 22px;\n              }\n\n              &.ant-select-item-option-active {\n                background-color: transparent;\n\n                @media (hover: hover) {\n                  &:hover {\n                    background-color: ",
        ";\n\n                    .ant-select-item-option-content {\n                      color: ",
        ";\n                    }\n                  }\n                }\n              }\n\n              &.ant-select-item-option-selected {\n                font-weight: bold;\n                background-color: transparent;\n\n                .ant-select-item-option-content {\n                  color: ",
        ";\n                }\n              }\n            }\n\n            &.search_type_dropdown {\n              .ant-select-item {\n                height: 37px;\n                padding: 0 0 0 24px;\n\n                .ant-select-item-option-content {\n                  line-height: 35px;\n                }\n              }\n            }\n\n            &.small-type-select {\n              .ant-select-item {\n                height: 32px;\n                padding: 0 0 0 12px;\n\n                .ant-select-item-option-content {\n                  line-height: 30px;\n                }\n              }\n            }\n          }\n\n          .ant-dropdown.util_dropdown {\n            .ant-dropdown-menu {\n              .ant-dropdown-menu-item {\n                @media (hover: hover) {\n                  &:hover {\n                    background-color: ",
        ";\n\n                    .ant-dropdown-menu-title-content {\n                      color: ",
        ";\n                    }\n                  }\n                }\n              }\n            }\n          }\n\n          .ant-modal-content {\n            .ant-modal-close {\n              top: 0;\n              right: 0;\n\n              &:hover {\n                background-color: transparent;\n              }\n            }\n          }\n\n          .ant-pagination-options {\n            display: none;\n          }\n        }\n\n        &.ant-dropdown-open {\n          &,\n          span svg {\n            color: ",
        ";\n          }\n        }\n      }\n    }\n  }\n\n  .util_login {\n    display: flex;\n\n    @media (max-width: 1200px) {\n      /* display: none; */\n      //justify-content: end;\n      //order: 2;\n      //width: calc(50% - 72px);\n      //margin-top: -16px;\n      //margin-right: -16px;\n      //z-index: 9;\n    }\n\n    @media (max-width: 768px) {\n    }\n\n    & > a {\n      display: flex;\n\n      &:not(:first-child) {\n        margin-left: 48px;\n\n        @media (max-width: 1200px) {\n          margin-left: 24px;\n        }\n      }\n    }\n\n    .cart-btn {\n      width: 32px;\n      height: 32px;\n      padding: 0;\n      border: none;\n      transition: opacity 0.2s;\n\n      @media (max-width: 768px) {\n      }\n      @media (max-width: 1200px) {\n        order: 2;\n        width: 24px;\n        min-width: 24px;\n        height: 24px;\n        background-size: contain;\n      }\n\n      &:before {\n        display: block;\n        content: '';\n        width: 100%;\n        height: 100%;\n        background: url('/static/assets/icon_shopping.svg') no-repeat 50% 50%;\n        background-size: contain;\n      }\n\n      &:hover {\n        opacity: 0.7;\n      }\n    }\n\n    .link-to-libraries {\n      position: relative;\n    }\n\n    .util_dropdown {\n    }\n  }\n\n  .util_logout {\n    display: flex;\n    justify-content: flex-end;\n    margin-right: -18px;\n\n    a {\n      display: block;\n      font-size: 16px;\n      padding: 18px;\n      color: ",
        ";\n      transition: opacity 0.2s;\n\n      &:hover {\n        opacity: 0.8;\n      }\n    }\n\n    @media (max-width: 1200px) {\n      display: none;\n    }\n    @media (max-width: 768px) {\n    }\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
function _templateObject1() {
    const data = _tagged_template_literal([
        "\n  display: none;\n  order: 3;\n  width: 24px;\n  height: 24px;\n  font-size: 0;\n\n  .dim {\n    position: fixed;\n    top: 63px;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 999;\n  }\n\n  .ant-btn {\n    width: 100%;\n    height: 100%;\n    padding: 16px;\n    border: none;\n\n    img {\n      display: block;\n      width: 100%;\n      height: 100%;\n    }\n  }\n\n  @media (max-width: 1200px) {\n    display: block;\n    width: 56px;\n    height: 56px;\n    margin-top: -16px;\n    margin-right: -16px;\n  }\n  @media (max-width: 768px) {\n  }\n"
    ]);
    _templateObject1 = function() {
        return data;
    };
    return data;
}
function _templateObject2() {
    const data = _tagged_template_literal([
        "\n  @media (max-width: 768px) {\n    height: ",
        "px;\n  }\n  @media (max-width: 1200px) {\n    //display: none;\n    height: ",
        "px;\n  }\n\n  &.header-fixed {\n    height: 85px;\n  }\n"
    ]);
    _templateObject2 = function() {
        return data;
    };
    return data;
}
function _templateObject3() {
    const data = _tagged_template_literal([
        "\n  .fixed-gnb-wrapper {\n    display: none;\n\n    .mobile-search-button {\n      display: none;\n      all: unset;\n      margin-right: 6px;\n      cursor: pointer;\n    }\n\n    .gnb-login-menu {\n      width: 270px;\n      /* display: none; */\n    }\n  }\n\n  @media (max-width: 1200px) {\n    /* flex-wrap: wrap; */\n  }\n  @media (max-width: 768px) {\n    .search-request-form {\n      display: none;\n    }\n\n    .gnb-login-menu,\n    .gnb-logout-menu {\n      /* display: none; */\n    }\n\n    .fixed-gnb-wrapper {\n      display: flex;\n      gap: 8px;\n\n      .mobile-search-button {\n        display: block;\n      }\n\n      .gnb-login-menu {\n        display: block;\n\n        p {\n          display: none;\n        }\n\n        .cart-link {\n          svg {\n            width: 24px;\n            height: 24px;\n          }\n        }\n\n        a:not(:nth-of-type(2)) {\n          display: none;\n        }\n\n        a:before {\n          display: none;\n        }\n      }\n    }\n  }\n"
    ]);
    _templateObject3 = function() {
        return data;
    };
    return data;
}
function _templateObject4() {
    const data = _tagged_template_literal([
        "\n  display: none;\n  padding: 0px 16px 12px;\n  position: relative;\n\n  @media (max-width: 768px) {\n    display: block;\n\n    &.fixed {\n      position: sticky;\n      z-index: 200;\n      top: 61px;\n      background-color: white;\n      left: 0;\n      right: 0;\n    }\n\n    &.block {\n      display: block;\n    }\n  }\n"
    ]);
    _templateObject4 = function() {
        return data;
    };
    return data;
}
function _templateObject5() {
    const data = _tagged_template_literal([
        "\n  padding: 24px 20px;\n\n  .gnb-logout-menu {\n    padding-bottom: 8px;\n\n    > a {\n      display: flex;\n      width: 100%;\n      align-items: center;\n      justify-content: center;\n    }\n  }\n\n  ul {\n    li {\n      padding: 13px 12px;\n      color: ",
        ";\n      font-size: 14px;\n      line-height: 22px; /* 157.143% */\n      cursor: pointer;\n    }\n  }\n\n  .logout-btn {\n    position: fixed;\n    bottom: 0;\n    border: none;\n    width: 70%;\n    text-align: left;\n    padding: 13px 0;\n    height: 48px;\n    color: ",
        ";\n    line-height: 22px; /* 157.143% */\n    border-top: 1px solid ",
        ";\n  }\n\n  .gnb-login-menu {\n    display: flex;\n    width: 100%;\n    gap: 4px;\n    padding-bottom: 20px;\n\n    > a {\n      display: flex;\n      border: 1px solid ",
        ";\n      border-radius: 6px;\n      padding: 9px 16px;\n      width: 100%;\n      align-items: center;\n      flex-direction: column;\n      gap: 2px;\n      justify-content: center;\n      font-weight: 600;\n      letter-spacing: -0.14px;\n      text-align: center;\n\n      i {\n        //margin-right: 4px;\n      }\n    }\n  }\n"
    ]);
    _templateObject5 = function() {
        return data;
    };
    return data;
}
function _templateObject6() {
    const data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 20px;\n  font-weight: 700;\n\n  img {\n    height: 24px;\n    width: auto;\n  }\n"
    ]);
    _templateObject6 = function() {
        return data;
    };
    return data;
}
function _templateObject7() {
    const data = _tagged_template_literal([
        "\n  position: relative;\n"
    ]);
    _templateObject7 = function() {
        return data;
    };
    return data;
}
function _templateObject8() {
    const data = _tagged_template_literal([
        "\n  display: flex;\n  gap: 8px;\n\n  > a {\n    padding: 4px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    border-radius: 6px;\n\n    :hover {\n      background-color: ",
        ";\n    }\n  }\n\n  p {\n    white-space: nowrap;\n  }\n\n  .studio-link {\n    position: relative;\n    @media (max-width: 1200px) {\n      display: none;\n    }\n  }\n\n  .link-to-libraries {\n    position: relative;\n\n    .guid-tooltip-wrapper {\n      p {\n        font-size: 12px;\n      }\n    }\n\n    @media (max-width: 1200px) {\n      display: none;\n    }\n  }\n\n  .ant-dropdown-trigger {\n    flex-direction: row;\n\n    .stack-centered {\n      display: flex;\n      align-items: center;\n      flex-direction: column;\n    }\n\n    p {\n      margin-bottom: 0;\n      font-size: 12px;\n      font-weight: 600;\n      line-height: 1.5;\n      color: ",
        ";\n    }\n\n    .stack-row {\n      display: flex;\n      align-items: center;\n      gap: 4px;\n    }\n  }\n\n  > a > p {\n    margin-bottom: 0;\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 1.5;\n    color: ",
        ";\n  }\n\n  .cart-link {\n    position: relative;\n\n    ::before {\n      content: '",
        "';\n      position: absolute;\n      top: -3px;\n      padding: 1px 5px 0px;\n      right: 6px;\n      //width: 13px;\n      //height: 15px;\n      color: #fff;\n      border-radius: 50%;\n      font-size: 11px;\n      font-weight: 600;\n      text-align: center;\n      line-height: 1.4;\n      background-color: ",
        ";\n      z-index: 1;\n    }\n\n    @media (max-width: 768px) {\n      ::before {\n        content: none;\n      }\n\n      p {\n        display: none;\n      }\n    }\n  }\n"
    ]);
    _templateObject8 = function() {
        return data;
    };
    return data;
}
function _templateObject9() {
    const data = _tagged_template_literal([
        "\n  display: flex;\n  gap: 8px;\n  position: relative;\n  width: 270px;\n  //flex: 1;\n  justify-content: flex-end;\n\n  > a {\n    line-height: 22px;\n    padding: 8px 15px;\n    border-radius: 6px;\n    font-size: 14px;\n    font-weight: 600;\n    transition: all 0.2s;\n\n    &:nth-child(2) {\n      border: ",
        " 1px solid;\n\n      :hover,\n      :active {\n        background-color: ",
        ";\n      }\n    }\n\n    &:last-child {\n      background-color: ",
        ";\n      color: ",
        ";\n      border: ",
        " 1px solid;\n\n      :hover,\n      :active {\n        background-color: ",
        ";\n      }\n    }\n  }\n\n  @media (max-width: 1200px) {\n    display: none;\n  }\n"
    ]);
    _templateObject9 = function() {
        return data;
    };
    return data;
}
function _templateObject10() {
    const data = _tagged_template_literal([
        "\n  position: absolute;\n  top: calc(100% + 8px);\n  left: 50%;\n  transform: translateX(-50%);\n  transition: opacity 0.2s;\n  pointer-events: none;\n  opacity: ",
        ";\n\n  p {\n    white-space: nowrap;\n    color: ",
        ";\n    padding: 8px 12px;\n    border-radius: 4px;\n    box-shadow: 6px 6px 20px 0px rgba(28, 31, 46, 0.12);\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 22px;\n    background: ",
        ";\n    text-align: center;\n\n    &::before {\n      content: '';\n      position: absolute;\n      bottom: 100%;\n      left: 50%;\n      transform: translateX(-50%);\n      border-width: 8px;\n      border-style: solid;\n      border-color: transparent transparent ",
        "\n        transparent;\n    }\n  }\n"
    ]);
    _templateObject10 = function() {
        return data;
    };
    return data;
}
function _templateObject11() {
    const data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  position: relative;\n  padding: 6px 16px;\n  border-radius: 18px;\n  border: 1px solid ",
        ";\n  background-color: ",
        ";\n  color: ",
        ";\n\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 24px; /* 150% */\n  letter-spacing: -0.16px;\n\n  outline: none;\n\n  :hover {\n    background-color: ",
        ";\n  }\n\n  &.active {\n    color: ",
        ";\n    background-color: ",
        ";\n  }\n\n  @media (max-width: 1200px) {\n    display: none;\n  }\n"
    ]);
    _templateObject11 = function() {
        return data;
    };
    return data;
}
function _templateObject12() {
    const data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n\n  @media (max-width: 1200px) {\n    display: none;\n  }\n"
    ]);
    _templateObject12 = function() {
        return data;
    };
    return data;
}
function _templateObject13() {
    const data = _tagged_template_literal([
        "\n  display: none;\n  padding: 4px 13px;\n\n  button {\n    all: unset;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n\n  p {\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 18px; /* 150% */\n  }\n\n  @media (max-width: 1200px) {\n    display: flex;\n    align-items: center;\n  }\n  @media (max-width: 768px) {\n    p {\n      display: none;\n    }\n\n    padding: 0;\n  }\n"
    ]);
    _templateObject13 = function() {
        return data;
    };
    return data;
}
import { Container } from "@/components";
import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";
export const GNBHeight = 74;
export const GNBHeightMobile = 104;
export const GNBWrapper = styled.div.withConfig({
    componentId: "sc-b44553bb-0"
})(_templateObject(), GNBHeight, themeGet("text.2", "#5F5F69"), themeGet("primary.0", "#002FA8"), themeGet("background.1", "#F5F8FB"), themeGet("primary.0", "#002FA8"), themeGet("primary.0", "#002FA8"), themeGet("background.1", "#F5F8FB"), themeGet("primary.0", "#002FA8"), themeGet("primary.0", "#002FA8"), themeGet("text.0", "#1B1B29"));
export const MobileMenu = styled.div.withConfig({
    componentId: "sc-b44553bb-1"
})(_templateObject1());
export const mobileHeaderHeight = 110;
export const FixedBox = styled.div.withConfig({
    componentId: "sc-b44553bb-2"
})(_templateObject2(), mobileHeaderHeight, mobileHeaderHeight);
export const StyledContainer = styled(Container).withConfig({
    componentId: "sc-b44553bb-3"
})(_templateObject3());
export const MobileSearchBar = styled.div.withConfig({
    componentId: "sc-b44553bb-4"
})(_templateObject4());
export const MobileMenuDrawer = styled.div.withConfig({
    componentId: "sc-b44553bb-5"
})(_templateObject5(), (props)=>props.theme.new.colors.sol_gray_700, (props)=>props.theme.new.colors.sol_gray_500, (props)=>props.theme.new.colors.sol_gray_100, (props)=>props.theme.new.colors.sol_gray_100);
export const DrawerHeader = styled.div.withConfig({
    componentId: "sc-b44553bb-6"
})(_templateObject6());
const StTemp = styled.div.withConfig({
    componentId: "sc-b44553bb-7"
})(_templateObject7());
export const LoginMenuWrapper = styled.div.withConfig({
    componentId: "sc-b44553bb-8"
})(_templateObject8(), (props)=>props.theme.new.colors.sol_gray_50, (props)=>props.theme.new.colors.gray700, (props)=>props.theme.new.colors.gray700, (props)=>props.count, (props)=>props.theme.new.colors.sol_red_400);
export const LogoutMenuWrapper = styled.div.withConfig({
    componentId: "sc-b44553bb-9"
})(_templateObject9(), (props)=>props.theme.new.colors.sol_gray_100, (props)=>props.theme.new.colors.sol_dim_gray, (props)=>props.theme.new.colors.sol_indigo_100, (props)=>props.theme.new.colors.sol_indigo_600, (props)=>props.theme.new.colors.sol_indigo_100, (props)=>props.theme.new.colors.sol_indigo_200);
export const StyledTooltip = styled.div.withConfig({
    componentId: "sc-b44553bb-10"
})(_templateObject10(), (props)=>props.visible ? 1 : 0, (props)=>props.theme.new.colors.white, (props)=>props.theme.new.colors.sol_blue_400, (props)=>props.theme.new.colors.sol_blue_400);
export const CategoryButton = styled.button.withConfig({
    componentId: "sc-b44553bb-11"
})(_templateObject11(), (props)=>props.theme.new.colors.sol_gray_100, (props)=>props.theme.new.colors.white, (props)=>props.theme.new.colors.sol_gray_700, (props)=>props.theme.new.colors.sol_dim_gray, (props)=>props.theme.new.colors.white, (props)=>props.theme.new.colors.sol_gray_700);
export const MypageMenu = styled.div.withConfig({
    componentId: "sc-b44553bb-12"
})(_templateObject12());
export const MenuHam = styled.div.withConfig({
    componentId: "sc-b44553bb-13"
})(_templateObject13());
