import axios from "axios";
import Cookies from "js-cookie";
export const cookieOptions = {
    path: "/",
    secure: process.env.NEXT_PUBLIC_ENV !== "development",
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN
};
const getDefaultTransfrom = (func)=>func ? Array.isArray(func) ? func : [
        func
    ] : [];
export const defaultTransformRequest = getDefaultTransfrom(axios.defaults.transformRequest);
export const defaultTransformResponse = getDefaultTransfrom(axios.defaults.transformResponse);
const transformRequest = [
    (data)=>{
        // return snakeize(data)
        return data;
    },
    ...defaultTransformRequest
];
const solvookAPIConfig = {
    baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT,
    responseType: "json",
    transformRequest,
    // headers: { Authorization: Cookies.get('accessToken') },
    transformResponse: [
        ...defaultTransformResponse,
        (data)=>{
            // return camelize(data)
            return data;
        }
    ]
};
const axiosInstance = axios.create(solvookAPIConfig);
export const getAccessToken = ()=>{
    return Cookies.get("accessToken", {
        ...cookieOptions
    });
};
const setupInterceptors = (ax)=>{
    ax.interceptors.request.use((config)=>{
        const accessToken = getAccessToken();
        if (accessToken) {
            config.headers.Authorization = accessToken;
        }
        return config;
    }, (error)=>{
        return Promise.reject(error);
    });
};
setupInterceptors(axiosInstance);
export const api = axiosInstance;
const axiosVersions = {
    market: {
        baseURL: "".concat(process.env.NEXT_PUBLIC_MARKET_API_ENDPOINT)
    }
};
export const otherApiClient = Object.entries(axiosVersions).reduce((acc, param)=>{
    let [k, v] = param;
    const instance = axios.create({
        ...solvookAPIConfig,
        ...v
    });
    setupInterceptors(instance);
    acc[k] = instance;
    return acc;
}, {});
