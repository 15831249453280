import { _ as _tagged_template_literal } from "@swc/helpers/_/_tagged_template_literal";
function _templateObject() {
    const data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: auto;\n  padding: 9px 16px;\n\n  border-radius: 6px;\n  font-weight: 600;\n  letter-spacing: -0.14px;\n\n  &.ant-btn-text:disabled {\n    background-color: ",
        " !important;\n    color: ",
        " !important;\n  }\n\n  &",
        " {\n    background-color: ",
        ";\n    color: ",
        ";\n\n    &.active,\n    &:hover {\n      background-color: ",
        ";\n      color: ",
        ";\n    }\n  }\n  &",
        " {\n    background-color: ",
        ";\n    color: ",
        ";\n\n    &.active,\n    &:hover {\n      background-color: ",
        ";\n      color: ",
        ";\n    }\n  }\n  &",
        " {\n    color: ",
        ";\n    border: 1px solid ",
        ";\n    &.active,\n    &:hover {\n      background-color: ",
        ";\n      color: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
import { Button } from "antd";
import styled from "styled-components";
export const ButtonVariants = {
    plain: "plain",
    outlined: "outlined",
    soft: "soft",
    solid: "solid"
};
const FilledButton = styled(Button).withConfig({
    componentId: "sc-78c9556b-0"
})(_templateObject(), (props)=>props.theme.new.colors.sol_gray_100, (props)=>props.theme.new.colors.sol_gray_300, (props)=>props.variant === ButtonVariants.solid ? ".ant-btn-text" : ".unavailable", (props)=>props.theme.new.colors.sol_indigo_500, (props)=>props.theme.new.colors.white, (props)=>props.theme.new.colors.sol_indigo_600, (props)=>props.theme.new.colors.white, (props)=>props.variant === ButtonVariants.soft ? ".ant-btn-text" : ".unavailable", (props)=>props.theme.new.colors.sol_indigo_100, (props)=>props.theme.new.colors.sol_indigo_600, (props)=>props.theme.new.colors.sol_indigo_200, (props)=>props.theme.new.colors.sol_indigo_600, (props)=>props.variant === ButtonVariants.outlined ? ".ant-btn-text" : ".unavailable", (props)=>props.theme.new.colors.sol_indigo_500, (props)=>props.theme.new.colors.sol_indigo_500, (props)=>props.theme.new.colors.sol_indigo_50, (props)=>props.theme.new.colors.sol_indigo_500);
export default FilledButton;
