import { otherApiClient } from ".";
export var SearchType;
(function(SearchType) {
    SearchType["ALL"] = "all";
    SearchType["TEXTBOOK"] = "textbook";
    SearchType["WORKBOOK"] = "workbook";
    SearchType["HANDOUT"] = "handout";
    SearchType["WORK"] = "work";
})(SearchType || (SearchType = {}));
export const defaultSearchType = "all";
export const searchQueryKeys = [
    "contentTypes",
    "dataTypes",
    "authors",
    "units",
    "editables",
    "publishers",
    "curriculums",
    "genres",
    "eras",
    "relatedBooks",
    "grades",
    "tickets"
];
export const sortKeys = [
    "POPULAR",
    "LATEST",
    "TITLE",
    "PRICE_ASC"
];
export const SortType = {
    POPULAR: "인기순",
    LATEST: "최신순",
    PRICE_ASC: "낮은가격순",
    TITLE: "이름순"
};
export const SearchQueryKey = {
    PRODUCT_TYPE: "productType",
    KEYWORD: "q",
    SORT: "sort",
    SUBJECT: "subject",
    CURRICULUMS: "curriculums",
    PUBLISHERS: "publishers",
    GRADES: "grades",
    CONTENTTYPES: "contentTypes",
    DATATYPES: "dataTypes",
    UNITS: "units",
    EDITABLES: "editables",
    AUTHORS: "authors",
    GENRES: "genres",
    ERAS: "eras",
    RELATEDBOOKS: "relatedBooks",
    TICKETS: "tickets"
};
export const BaseFilterKeyToLabel = {
    contentTypes: "학습 내용",
    dataTypes: "자료유형",
    authors: "제작자",
    units: "단원",
    editables: "편집 가능",
    publishers: "출판사",
    curriculums: "교육 과정",
    genres: "장르",
    eras: "시대",
    relatedBooks: "교재",
    grades: "학년",
    tickets: "이용권"
};
const filterValidFilters = (filters)=>{
    return Object.entries(filters).reduce((acc, param)=>{
        let [key, value] = param;
        if (Array.isArray(value) ? value.length > 0 : value) {
            acc[key] = value;
        }
        return acc;
    }, {});
};
export const getSearchFilter = async (query)=>{
    const { filters , productType , ...rest } = query;
    const validFilters = filters ? filterValidFilters(filters) : undefined;
    const { data  } = await otherApiClient.market.get("/search/filter", {
        params: {
            ...rest,
            productType: productType,
            ...validFilters ? {
                filter: JSON.stringify({
                    [productType]: validFilters
                })
            } : null
        }
    });
    return data;
};
export const getSearchProducts = async (query)=>{
    const { filters , productType , ...rest } = query;
    const { data  } = await otherApiClient.market.get("/search", {
        params: {
            ...rest,
            productType: productType,
            filter: JSON.stringify({
                [productType]: filterValidFilters(filters)
            })
        }
    });
    return data;
};
export const getSearchTerms = async ()=>{
    const { data  } = await otherApiClient.market.get("/search/terms", {});
    return data;
};
