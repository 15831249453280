import { useRouter } from "next/router";
import { useMemo } from "react";
import { isSubjects } from "src/api/product";
export const useLibrarySubject = ()=>{
    const { query  } = useRouter();
    return useMemo(()=>{
        const value = query.subject;
        const result = value ? [
            ...value instanceof Array ? value : [
                value
            ]
        ] : [];
        if (isSubjects(result[0])) {
            return result[0];
        }
        return null;
    }, [
        query
    ]);
};
