import { _ as _tagged_template_literal } from "@swc/helpers/_/_tagged_template_literal";
function _templateObject() {
    const data = _tagged_template_literal([
        "\n      width: 100%;\n      max-width: none !important;\n    "
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
function _templateObject1() {
    const data = _tagged_template_literal([
        "\n        padding-left: 0;\n        padding-right: 0;\n      "
    ]);
    _templateObject1 = function() {
        return data;
    };
    return data;
}
function _templateObject2() {
    const data = _tagged_template_literal([
        "\n      padding-left: 30px;\n      padding-right: 30px;\n\n      @media (max-width: 1200px) {\n        padding-left: 32px;\n        padding-right: 32px;\n      }\n      @media only screen and (max-width: 768px) {\n        padding-left: 16px;\n        padding-right: 16px;\n      }\n    "
    ]);
    _templateObject2 = function() {
        return data;
    };
    return data;
}
function _templateObject3() {
    const data = _tagged_template_literal([
        "\n      width: 100% !important;\n      max-width: 100% !important;\n      @media only screen and (min-width: 1441px) {\n        padding-left: 64px;\n        padding-right: 64px;\n      }\n    "
    ]);
    _templateObject3 = function() {
        return data;
    };
    return data;
}
function _templateObject4() {
    const data = _tagged_template_literal([
        "\n  max-width: 1260px;\n  width: 100%;\n  margin-left: auto;\n  margin-right: auto;\n\n  ",
        ";\n  ",
        ";\n\n  ",
        ";\n\n  @media (max-width: 768px) {\n  }\n  @media (max-width: 992px) {\n  }\n  @media (max-width: 1200px) {\n    //overflow: hidden;\n    max-width: inherit;\n  }\n"
    ]);
    _templateObject4 = function() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
const ContainerWrapper = styled.div.withConfig({
    componentId: "sc-7b953523-0"
})(_templateObject4(), (props)=>props.fullWidth && css(_templateObject()), (props)=>props.noGutter && css(_templateObject1()) || css(_templateObject2()), (props)=>props.fluid && css(_templateObject3()));
export default ContainerWrapper;
