import { api } from "../../../api";
export class GetLabByAuthorIdCommand {
    execute(dto) {
        const { author_id  } = dto;
        const url = "/labs/author/".concat(author_id);
        return api(url, {
            method: "GET"
        }).then((res)=>res.data);
    }
    constructor(apiService){
        this.apiService = apiService;
    }
}
