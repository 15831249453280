import Router from "next/router";
function redirect(target, ctx, options) {
    if (ctx && ctx.res) {
        // server
        // 303: "See other"
        // 302: "Found"
        ctx.res.writeHead(302, {
            Location: target
        });
        // ctx.res.finished = true
        ctx.res.end();
    } else {
        // import Router from 'next/router'
        // In the browser, we just pretend like this never even happened ;)
        if (options === null || options === void 0 ? void 0 : options.redirectUrl) {
            Router.replace("".concat(target, "?redirect_url=").concat(options.redirectUrl));
        } else {
            Router.replace("".concat(target));
        }
    }
}
export { redirect };
