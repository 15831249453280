import { _ as _tagged_template_literal } from "@swc/helpers/_/_tagged_template_literal";
function _templateObject() {
    const data = _tagged_template_literal([
        "\n  width: 100%;\n  height: 100vh;\n\n  gap: 16px;\n  top: 72px;\n  position: fixed;\n  background: rgba(28, 31, 46, 0.5);\n  z-index: 11;\n  display: flex;\n  justify-content: center;\n\n  overflow: hidden;\n\n  @media (max-width: 1180px) {\n    display: none;\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
function _templateObject1() {
    const data = _tagged_template_literal([
        "\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 412px;\n\n  width: 100%;\n  height: 100%;\n  max-height: 600px;\n  max-width: 832px;\n\n  background-color: #fff;\n  border-radius: 6px;\n\n  margin: 16px 0;\n"
    ]);
    _templateObject1 = function() {
        return data;
    };
    return data;
}
function _templateObject2() {
    const data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: calc(600px - 76px);\n  overflow-y: auto;\n\n  &.container-mobile {\n    height: 100%;\n    padding: 20px 0;\n    flex-grow: 1;\n  }\n\n  @media (max-width: 1199px) {\n    overflow-y: auto;\n\n    &.depth-one-container {\n      height: calc(100vh - 368px);\n      max-height: calc(var(--svh, 1svh) * 100 - 268px);\n    }\n\n    &.depth-two-row-container {\n      height: 100%;\n    }\n  }\n"
    ]);
    _templateObject2 = function() {
        return data;
    };
    return data;
}
function _templateObject3() {
    const data = _tagged_template_literal([
        ""
    ]);
    _templateObject3 = function() {
        return data;
    };
    return data;
}
function _templateObject4() {
    const data = _tagged_template_literal([
        "\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n"
    ]);
    _templateObject4 = function() {
        return data;
    };
    return data;
}
function _templateObject5() {
    const data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n  width: 90%;\n  word-break: auto-phrase;\n\n  @media (max-width: 1199px) {\n    width: 100%;\n  }\n"
    ]);
    _templateObject5 = function() {
        return data;
    };
    return data;
}
function _templateObject6() {
    const data = _tagged_template_literal([
        "\n  display: flex;\n  height: fit-content;\n  max-height: 600px;\n  flex-direction: column;\n  gap: 16px;\n  flex-shrink: 0;\n\n  margin: 16px 0;\n"
    ]);
    _templateObject6 = function() {
        return data;
    };
    return data;
}
function _templateObject7() {
    const data = _tagged_template_literal([
        "\n  padding: 0 32px;\n  background-color: ",
        ";\n  border-radius: 6px;\n\n  :hover {\n    background-color: ",
        ";\n  }\n"
    ]);
    _templateObject7 = function() {
        return data;
    };
    return data;
}
import { styled as muiStyled } from "@mui/material";
import styled from "styled-components";
export const CategoryContainer = styled.div.withConfig({
    componentId: "sc-b50d693b-0"
})(_templateObject());
export const CategoryBox = styled.section.withConfig({
    componentId: "sc-b50d693b-1"
})(_templateObject1());
export const RowContainer = styled.div.withConfig({
    componentId: "sc-b50d693b-2"
})(_templateObject2());
export const BoxContainer = styled.div.withConfig({
    componentId: "sc-b50d693b-3"
})(_templateObject3());
export const MenuLinkBox = muiStyled("div")((param)=>{
    let { theme  } = param;
    return {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRight: "1px solid ".concat(theme.palette.grey[50]),
        "&:last-of-type": {
            borderRight: "none"
        },
        "&.link-box-mobile ": {
            height: "100%",
            maxHeight: "calc(var(--svh, 1svh) * 100 - 268px)",
            width: "120px",
            flexShrink: 0,
            borderRight: "1px solid ".concat(theme.palette.grey[50])
        },
        "&._depth-two": {
            maxHeight: "calc(var(--svh, 1svh) * 100 - 58px)"
        },
        "&.depth-one-big-width": {
            padding: "20px 0"
        }
    };
});
export const Box = muiStyled("ul")(()=>({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        padding: "0 16px",
        "@media (max-width: 768px)": {
            padding: "0 8px"
        }
    }));
export const ButtonBox = muiStyled("li")((param)=>{
    let { theme  } = param;
    return {
        a: {
            width: "100%"
        },
        button: {
            width: "100%",
            height: "32px",
            backgroundColor: "transparent",
            borderRadius: "4px",
            border: "1px solid ".concat(theme.palette.grey["200"]),
            cursor: "pointer",
            ...theme.typography.semibold14,
            color: theme.palette.text.secondary,
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            padding: 0,
            ":hover": {
                backgroundColor: theme.palette.grey[50]
            }
        },
        "&.active": {
            button: {
                border: "1px solid ".concat(theme.palette.primary.main),
                color: theme.palette.primary.main
            }
        }
    };
});
export const TwoColumnBox = styled.div.withConfig({
    componentId: "sc-b50d693b-4"
})(_templateObject4());
export const BoxColumn = muiStyled("div")((param)=>{
    let { theme  } = param;
    return {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        padding: "8px 16px",
        "&.active, &:hover": {
            backgroundColor: "rgba(169, 175, 199, 0.20)",
            cursor: "pointer",
            p: {
                color: theme.palette.indigo["500"],
                ...theme.typography.semibold16
            }
        },
        "@media (max-width: 1199px)": {
            padding: "12px 20px",
            backgroundColor: "transparent",
            p: {
                color: theme.palette.text.primary,
                ...theme.typography.regular16
            }
        }
    };
});
export const BoxTitle = muiStyled("p")((param)=>{
    let { theme  } = param;
    return {
        ...theme.typography.semibold16,
        padding: "0 16px",
        color: theme.palette.grey["700"],
        marginBottom: "12px",
        a: {
            display: "flex",
            alignItems: "center",
            gap: "4px",
            ":hover": {
                textDecoration: "2px underline"
            }
        },
        "@media (max-width: 1199px)": {
            padding: "20px 16px 12px 16px",
            marginBottom: "0"
        }
    };
});
export const LabelBox = styled.div.withConfig({
    componentId: "sc-b50d693b-5"
})(_templateObject5());
export const Label = muiStyled("p")((param)=>{
    let { theme  } = param;
    return {
        ...theme.typography.regular16,
        color: theme.palette.grey["500"],
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    };
});
export const MenuAdBox = styled.div.withConfig({
    componentId: "sc-b50d693b-6"
})(_templateObject6());
export const AdStack = styled.div.withConfig({
    componentId: "sc-b50d693b-7"
})(_templateObject7(), (props)=>props.theme.new.colors.sol_gray_50, (props)=>props.theme.new.colors.sol_gray_100);
