import { otherApiClient } from ".";
export const getCategoryMenu = async (categoryId)=>{
    const { data  } = await otherApiClient.market("/menuCategories", {
        params: {
            categoryId
        }
    });
    return data;
};
export const SortType = {
    POPULAR: "인기순",
    NEW: "최신순",
    LOWPRICE: "낮은가격순",
    NAME: "이름순"
};
export const DetailFilterKeys = {
    UNITS: "단원",
    DATATYPES: "자료유형",
    AUTHORS: "제작자",
    RELATEDBOOKS: "교재",
    TICKETS: "이용권"
};
export const CategoryQueryKey = {
    CATEGORY: "id",
    SORT: "sort"
};
export const getCategoryList = async (param)=>{
    let { id , ...rest } = param;
    const { data  } = await otherApiClient.market("/menuCategories/".concat(id), {
        params: {
            ...rest
        }
    });
    return data;
};
