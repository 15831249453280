import { otherApiClient } from ".";
export const getMyCoupons = async ()=>{
    const { data  } = await otherApiClient.market("/coupons/user");
    return data;
};
export const getHandoutCoupons = async (params)=>{
    const { data  } = await otherApiClient.market("/coupons", {
        params
    });
    return data;
};
export const getCouponFilters = async ()=>{
    const { data  } = await otherApiClient.market("/coupons/receivable/filters");
    return data;
};
export const getReceivableCoupons = async (param)=>{
    let { subject , category  } = param;
    const { data  } = await otherApiClient.market("/coupons/receivable", {
        params: {
            subject,
            category
        }
    });
    return data;
};
export const postUserCoupon = async (couponId)=>{
    const { data  } = await otherApiClient.market.post("/coupons/user", {
        couponId
    });
    return data;
};
export const patchUserCoupon = async (request)=>{
    const { data  } = await otherApiClient.market.patch("/coupons/user/orders", {
        ...request
    });
    return data;
};
export const postProductCoupon = async (productId)=>{
    const { data  } = await otherApiClient.market.post("/coupons/products", {
        productId
    });
    return data;
};
