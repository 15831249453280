import useSearchParams from "@/hooks/useSearchParams";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { FilterKey } from "src/api/brand";
import { SearchQueryKey } from "src/api/search";
import { CategoryQueryKey, DetailFilterKeys } from "../api/category";
const routerOptions = {
    shallow: true,
    scroll: true
};
export var FilterQueryHandlerType;
(function(FilterQueryHandlerType) {
    FilterQueryHandlerType["RESET"] = "reset";
    FilterQueryHandlerType["DETAILFILTER"] = "detailFilter";
    FilterQueryHandlerType["CATEGORY"] = "category";
    FilterQueryHandlerType["SORT"] = "sort";
})(FilterQueryHandlerType || (FilterQueryHandlerType = {}));
// @TODO filter의 타입이 바뀌거나, 추가되어도 대응이 가능해야 한다.
// 네이밍을 useFilterHandler로 변경하는게 좋을것 같네요.
export const useCategoryFilterHandler = (props)=>{
    const { query , push , pathname , asPath  } = useRouter();
    const searchParams = useSearchParams();
    const checkAsPath = useMemo(()=>{
        return (props === null || props === void 0 ? void 0 : props.isUseAsPath) ? asPath.split("?")[0] : pathname;
    }, [
        asPath,
        pathname
    ]);
    const navigationHandler = useCallback((parameter)=>{
        // 브랜드의 경우, rewrite로 path 경로를 재설정 하기 때문에 labs 카테고리인지 한번 확인하는 과정을 넣었습니다.
        const path = (props === null || props === void 0 ? void 0 : props.isUseAsPath) ? checkAsPath : pathname;
        push({
            pathname: path,
            query: parameter.toString()
        }, undefined, (props === null || props === void 0 ? void 0 : props.isUseAsPath) ? props.routerOptions : routerOptions);
    }, [
        push
    ]);
    const handleSortFilter = (sortKey)=>{
        searchParams.delete(CategoryQueryKey.SORT);
        //페이지네이션 초기화
        resetPagination();
        searchParams.append(CategoryQueryKey.SORT, sortKey);
        navigationHandler(searchParams);
    };
    //영어로 쿼리키가 나가야함.
    const handleDetailFilter = (filterName, filterItem)=>{
        //pagination 초기화
        resetPagination();
        const isExist = searchParams.getAll(filterName).includes(filterItem.toString());
        if (isExist) {
            const newFilter = searchParams.getAll(filterName).filter((item)=>item !== filterItem.toString());
            searchParams.delete(filterName);
            if (newFilter.length > 0) {
                newFilter.map((item)=>searchParams.append(filterName, item));
            }
        } else {
            searchParams.append(filterName, filterItem.toString());
        }
        navigationHandler(searchParams);
    };
    const handleResetFilter = ()=>{
        //productType 제외 모든 query 삭제
        //pagination 초기화
        resetPagination();
        for(const key in SearchQueryKey){
            if (props === null || props === void 0 ? void 0 : props.isUseAsPath) searchParams.delete(FilterKey[key]);
            else searchParams.delete(DetailFilterKeys[key]);
        }
        navigationHandler(searchParams);
    };
    const handleCategoryMenu = useCallback((category, path)=>{
        push({
            pathname: path ? checkAsPath + path : checkAsPath,
            query: {
                [CategoryQueryKey.CATEGORY]: category
            }
        }, undefined, (props === null || props === void 0 ? void 0 : props.isUseAsPath) ? props.routerOptions : routerOptions);
    }, [
        searchParams
    ]);
    const selectedFilterList = useMemo(()=>{
        let selectedFilters = [];
        Object.keys(query).forEach((key)=>{
            //Querykey에 없는 key는 제외
            //categoryId는 제외
            if (!Object.values(DetailFilterKeys).includes(key)) return;
            searchParams.getAll(key).map((item)=>{
                // if (key === QueryKey.CATEGORY) return
                selectedFilters.push({
                    key: key,
                    value: item
                });
            });
        });
        return selectedFilters;
    }, [
        searchParams
    ]);
    const resetPagination = useCallback(()=>{
        searchParams.delete("page");
    }, [
        query
    ]);
    const selectedSort = useMemo(()=>{
        return searchParams.get(CategoryQueryKey.SORT);
    }, [
        searchParams
    ]);
    const hasSelectedFilter = useMemo(()=>{
        return selectedFilterList.length > 0;
    }, [
        selectedFilterList
    ]);
    const isCheckFilterItem = (filterName, filterItem)=>{
        return searchParams.getAll(filterName).includes(filterItem.toString());
    };
    const isCheckFilterCount = (filterName)=>{
        return searchParams.getAll(filterName).length;
    };
    const filterQueryHandler = (param)=>{
        let { filterType , filterName , value  } = param;
        switch(filterType){
            case "reset":
                handleResetFilter();
                break;
            case "detailFilter":
                handleDetailFilter(filterName, value);
                break;
            case "category":
                handleCategoryMenu(value);
                break;
            case "sort":
                handleSortFilter(value);
                break;
            default:
                break;
        }
    };
    //getBooksProduct로 전송할 쿼리
    const getFilteredQuery = useMemo(()=>{
        const filteredQuery = {};
        searchParams.forEach((value, key)=>{
            filteredQuery[key] = value;
        });
        return filteredQuery;
    }, [
        searchParams
    ]);
    return {
        selectedFilterList,
        selectedSort,
        hasSelectedFilter,
        getFilteredQuery,
        isCheckFilterItem,
        isCheckFilterCount,
        filterQueryHandler,
        handleCategoryMenu,
        searchParams
    };
};
