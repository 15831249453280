import { create } from "zustand";
import { Subjects } from "../../../api/product";
import { SearchType, SortType } from "../../../api/search";
export const SortTypeToString = {
    [SortType.POPULAR]: "인기순",
    [SortType.LATEST]: "최신순",
    [SortType.PRICE_ASC]: "가격순",
    [SortType.TITLE]: "제목순"
};
export const useSearchStore = create((set)=>({
        items: [],
        query: {},
        filters: {},
        selectedFilters: [],
        baseLicenses: [],
        search_type: SearchType.HANDOUT,
        search_keyword: "",
        prevKeyword: "",
        prevBaseLicense: undefined,
        subject: undefined,
        loadingTemp: "",
        totalCount: "0",
        filter_parsing: false,
        emptyTooltipOpen: false,
        sliderOpen: {
            isOpen: false,
            value: ""
        },
        count: {
            [Subjects.EN]: "0",
            [Subjects.KO]: "0",
            [Subjects.MA]: "0"
        },
        updateFilters: (newFilters)=>{
            set({
                filters: newFilters
            });
        },
        updateBaseLicenses: (newLicenses)=>{
            set({
                baseLicenses: newLicenses
            });
        },
        updateSelectedFilters: (newSelectedFilters)=>{
            set({
                selectedFilters: newSelectedFilters
            });
        },
        updateQuery: (query)=>{
            set({
                query: query
            });
        },
        updateKeyword: (value)=>{
            set({
                search_keyword: value
            });
        },
        updatePrevKeyword: (value)=>{
            set({
                prevKeyword: value
            });
        },
        updateSubject: (value)=>{
            set({
                subject: value
            });
        },
        updatePrevBaseLicense: (value)=>{
            set({
                prevBaseLicense: value
            });
        },
        updateSearchType: (value)=>{
            set({
                search_type: value
            });
        },
        updateLoadingTemp: (value)=>{
            set({
                loadingTemp: value
            });
        },
        generateUrl: (query)=>{
            let path = "/search?";
            if (!query) return path;
            Object.keys(query).forEach((key)=>{
                query[key] && (path += key + "=" + query[key] + "&");
            });
            return path;
        },
        updateTotalCount: (value)=>{
            set({
                totalCount: value
            });
        },
        updateFilterParsing: (value)=>{
            set({
                filter_parsing: value
            });
        },
        updateEmptyTooltipOpen: (value)=>{
            set({
                emptyTooltipOpen: value
            });
        },
        openSlider: (isOpen, value)=>{
            set({
                sliderOpen: {
                    isOpen,
                    value
                }
            });
        },
        updateCount: (value)=>{
            set({
                count: value
            });
        }
    }));
