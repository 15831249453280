import { getCookie, setCookie } from "./sessions";
export const DEFAULT_FIREBASE_COOKIE = "__session" //firebase only accept __session cookie
;
export const setFirebaseCookie = (key, token)=>{
    // get cookie from __session using getCookie function
    // parse the data from cookie
    // assign the token to the key
    // call set cookie function with the new data
    try {
        const cookieData = getCookie(DEFAULT_FIREBASE_COOKIE);
        const data = cookieData ? JSON.parse(cookieData) : {};
        data[key] = token;
        setCookie(DEFAULT_FIREBASE_COOKIE, data);
    } catch (error) {
        ;
    }
};
export const removeFirebaseCookie = (key)=>{
    // get cookie from __session  using getCookie function
    // parse the data from cookie
    // remove the key from the data
    // call set cookie function with the new data
    try {
        const cookieData = getCookie(DEFAULT_FIREBASE_COOKIE);
        const data = cookieData ? JSON.parse(cookieData) : {};
        if (data && data.hasOwnProperty(key)) {
            delete data[key];
            setCookie(DEFAULT_FIREBASE_COOKIE, data);
        } else {
            ;
        }
    } catch (error) {
        ;
    }
};
export const getFirebaseCookieFromClient = (key)=>{
    // get cookie from __session using getCookie function
    // parse the data from cookie
    // get the relatedData using the key
    try {
        const cookieData = getCookie(DEFAULT_FIREBASE_COOKIE);
        const data = cookieData ? JSON.parse(cookieData) : {};
        if (data && data.hasOwnProperty(key)) {
            return data[key];
        } else {
            ;
        }
    } catch (error) {
        ;
    }
};
