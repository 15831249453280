import { otherApiClient } from ".";
import { toQueryStringCamel } from "../utils/misc";
export const ActionType = {
    view3minute: "view_3minute",
    print: "print"
};
export const ItemType = {
    handout: "handout",
    studio: "studioHandout"
};
export var PassCategory;
(function(PassCategory) {
    PassCategory["Solvookplus"] = "solvookplus";
    PassCategory["Ticket"] = "ticket";
    PassCategory["Subscription"] = "solvookplus-subscribe";
    PassCategory["Expert"] = "expert";
    PassCategory["ExpertSubscription"] = "expert-subscribe";
    PassCategory["Package"] = "package";
})(PassCategory || (PassCategory = {}));
export const PassCategoryWithString = {
    ["solvookplus"]: "쏠북 플러스",
    ["ticket"]: "이용권"
};
export const ExpiredReasonWithString = {
    COUNT: "사용횟수 소진",
    AMOUNT: "포인트 소진",
    PERIOD: "유효기간 만료",
    REFUND: "환불 만료"
};
var UnUsableReason;
(function(UnUsableReason) {
    UnUsableReason["SUBJECT"] = "이용가능 과목 조건 불일치";
    UnUsableReason["PUBLISHER"] = "이용가능 출판사 조건 불일치";
    UnUsableReason["CURRICULUM"] = "이용가능 교육과정 조건 불일치";
    UnUsableReason["AUTHOR"] = "이용가능 저자 조건 불일치";
    UnUsableReason["PRODUCT_TYPE"] = "이용가능 상품 유형 조건 불일치";
    UnUsableReason["PRICE"] = "이용가능 가격 조건 불일치";
})(UnUsableReason || (UnUsableReason = {}));
export const postMembershipLog = async (params)=>{
    const { data  } = await otherApiClient.market.post("/membership-use-logs", {
        ...params
    });
    return data;
};
export const getPassProducts = async (params)=>{
    const { data  } = await otherApiClient.market("/memberships/products".concat(toQueryStringCamel(params)));
    return data;
};
export const getPassOneProduct = async (id)=>{
    const { data  } = await otherApiClient.market("/memberships/products/".concat(id));
    return data;
};
export const getMemberships = async (params)=>{
    const { data  } = await otherApiClient.market.get("/memberships", {
        params
    });
    return data;
};
export const patchAddPass = async (param)=>{
    const { data  } = await otherApiClient.market.patch("/memberships/tickets", param);
    return data;
};
export const postMembershipSubscribe = async (params)=>{
    const { data  } = await otherApiClient.market.post("/memberships/subscription", params);
    return data;
};
export const getCancelMembership = async ()=>{
    const { data  } = await otherApiClient.market.get("/memberships/subscription/cancel");
    return data;
};
