import Cookies from "js-cookie";
import { cookieOptions, otherApiClient } from ".";
export const getMe = async (accessToken)=>{
    var _data_option;
    Cookies.set("accessToken", accessToken, {
        ...cookieOptions
    });
    const headers = {
        Authorization: accessToken
    };
    const { data  } = await otherApiClient.market.get("/users/meta", {
        headers
    });
    return {
        user: {
            displayName: data.nickname,
            email: data.email,
            phoneNumber: data.phone,
            photoURL: null,
            providerId: data.provider.channel,
            uid: data.uid,
            mainSubject: data.mainSubject,
            job: data.job,
            id: data.userId,
            emailVerified: data.emailVerified,
            address: data.address,
            flags: {
                is_use_lab: data === null || data === void 0 ? void 0 : (_data_option = data.option) === null || _data_option === void 0 ? void 0 : _data_option.use_lab
            },
            brand: data.brand
        }
    };
};
