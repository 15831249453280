import cookie from "js-cookie";
export const DEFAULT_SESSION_COOKIE_NAME = "session_id";
export const DEFAULT_USER_COOKIE_NAME = "save_id";
export const setCookie = function(key, value) {
    let expires = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 1;
    if ("object" === "undefined") {
        cookie.set(key, value, {
            expires,
            path: "/"
        });
    }
};
export const removeCookie = (key)=>{
    if ("object" === "undefined") {
        cookie.remove(key, {
            expires: 1
        });
    }
};
export const getCookie = (key, req)=>{
    if ("object" === "undefined") {
        return getCookieFromBrowser(key);
    } else {
        return getCookieFromServer(key, req);
    }
};
export const getCookieFromBrowser = (key)=>{
    return cookie.get(key);
};
export const getCookieFromServer = (key, req)=>{
    if (!req) {
        return null;
    }
    const { headers  } = req;
    if (!headers || !headers.cookie) {
        return null;
    }
    const rawCookie = headers.cookie.split(";").find((c)=>c.trim().startsWith("".concat(key, "=")));
    if (!rawCookie) {
        return null;
    }
    return rawCookie.split("=")[1];
};
