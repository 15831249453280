import useRedirect from "@/hooks/useRedirect";
import { useAuthStore } from "@/lib/auth/AuthStore";
import { mixpanelEvent } from "@/lib/mixpanels/mixpanel";
import { EBOOKS_ORDER, LIBRARIES_INDEX_PAGE, LOGIN_PAGE, MYPAGE_COUPONS, MYPAGE_PACKAGES_INDEX_PAGE, MYPAGE_PAGE, MYPAGE_PASSES, MYPAGE_STORES_PRODUCTS_INDEX, REGISTRATION_PAGE, SHOP_CART_PAGE, SOLVOOK_WRITE_STUDIO } from "@/settings/constant";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useSideCategoryMenuStore } from "../../SideSheets/store/useCategoryMenuStore";
const MixPanelPropertyType = {
    [SHOP_CART_PAGE]: {
        name: "장바구니",
        type: "cart"
    },
    [EBOOKS_ORDER]: {
        name: "장바구니",
        type: "cart"
    },
    [SOLVOOK_WRITE_STUDIO]: {
        name: "엑스퍼트",
        type: "expert"
    },
    [LIBRARIES_INDEX_PAGE]: {
        name: "자료보관함",
        type: "library"
    },
    [LOGIN_PAGE]: {
        name: "로그인",
        type: "login"
    },
    [REGISTRATION_PAGE]: {
        name: "회원가입",
        type: "signup"
    },
    [MYPAGE_PAGE]: {
        name: "마이쏠북",
        type: "mypage"
    },
    [MYPAGE_COUPONS]: {
        name: "쿠폰 관리",
        type: "mypageCoupons"
    },
    [MYPAGE_PASSES]: {
        name: "이용권 관리",
        type: "mypagePasses"
    },
    [MYPAGE_STORES_PRODUCTS_INDEX]: {
        name: "자료 판매",
        type: "mypageStores"
    },
    [MYPAGE_PACKAGES_INDEX_PAGE]: {
        name: "패키지보관함",
        type: "mypagePackages"
    }
};
const useGNBHandler = ()=>{
    var _user_brand;
    const { push  } = useRouter();
    const { redirectTo  } = useRedirect();
    const { user , isLoggedIn  } = useAuthStore();
    const { isOpenSideMenu , openSideMenu , closeSideMenu , openMobileSideMenu  } = useSideCategoryMenuStore();
    const queueMixpanel = useCallback((type, name)=>{
        mixpanelEvent("GNB Button Clicked", {
            "button type": type,
            "button name": name
        });
    }, []);
    const handleDropdownClick = useCallback((url)=>{
        var _MixPanelPropertyType_url;
        const name = url.includes("@") ? "브랜드 홈" : (_MixPanelPropertyType_url = MixPanelPropertyType[url]) === null || _MixPanelPropertyType_url === void 0 ? void 0 : _MixPanelPropertyType_url.name;
        queueMixpanel("mypage", name);
        push(url);
    }, [
        user
    ]);
    const handleCategoryButton = ()=>{
        if (isOpenSideMenu) closeSideMenu();
        else openSideMenu();
        queueMixpanel("category", "카테고리");
    };
    const handlePageMove = (url, redirect)=>{
        var _MixPanelPropertyType_url, _MixPanelPropertyType_url1;
        queueMixpanel((_MixPanelPropertyType_url = MixPanelPropertyType[url]) === null || _MixPanelPropertyType_url === void 0 ? void 0 : _MixPanelPropertyType_url.type, (_MixPanelPropertyType_url1 = MixPanelPropertyType[url]) === null || _MixPanelPropertyType_url1 === void 0 ? void 0 : _MixPanelPropertyType_url1.name);
        if (redirect) {
            redirectTo(url);
            return;
        }
        push(url);
    };
    const handleSideMenu = ()=>{
        queueMixpanel("menu", "메뉴");
        openMobileSideMenu();
    };
    const labName = user === null || user === void 0 ? void 0 : (_user_brand = user.brand) === null || _user_brand === void 0 ? void 0 : _user_brand.name;
    return {
        handleDropdownClick,
        handleCategoryButton,
        handlePageMove,
        isOpenSideMenu,
        handleSideMenu,
        labName,
        queueMixpanel
    };
};
export default useGNBHandler;
