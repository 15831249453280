import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import useSearchParams from "./useSearchParams";
import { FilterTypeToQueryKey, QueryKey } from "../api/booksProducts";
import { useBooks } from "@/components/Books/Order";
import { BOOKS_LANDING, EBOOKS_LANDING } from "@/settings/constant";
const routerOptions = {
    shallow: true,
    scroll: true
};
export const useBooksQuery = ()=>{
    const { asPath , query , push , pathname  } = useRouter();
    const { isBooks  } = useBooks();
    const searchParams = useSearchParams();
    const keyword = useMemo(()=>{
        return searchParams.get(QueryKey.KEYWORD) || "";
    }, [
        searchParams
    ]);
    const selectedFilterList = useMemo(()=>{
        let selectedFilters = [];
        Object.keys(query).forEach((key)=>{
            //Querykey에 없는 key는 제외
            if (!Object.values(QueryKey).includes(key)) return;
            searchParams.getAll(key).map((item)=>{
                selectedFilters.push({
                    key: key,
                    value: item
                });
            });
        });
        return selectedFilters;
    }, [
        searchParams
    ]);
    const hasSelectedFilter = useMemo(()=>{
        return selectedFilterList.length > 0;
    }, [
        selectedFilterList
    ]);
    const handleDetailFilter = (filterType, id, value)=>{
        const isExist = searchParams.getAll(filterType).includes(id.toString());
        if (isExist) {
            const newFilter = searchParams.getAll(filterType).filter((item)=>item !== id.toString());
            searchParams.delete(filterType);
            if (newFilter.length > 0) {
                newFilter.map((item)=>searchParams.append(filterType, item));
            }
        } else {
            searchParams.append(filterType, id.toString());
        }
        push({
            pathname,
            query: searchParams.toString()
        }, undefined, routerOptions);
    };
    const handleResetFilter = ()=>{
        //productType 제외 모든 query 삭제
        for(const key in FilterTypeToQueryKey){
            searchParams.delete(FilterTypeToQueryKey[key]);
        }
        searchParams.delete(QueryKey.KEYWORD);
        push({
            pathname,
            query: searchParams.toString()
        }, undefined, routerOptions);
    };
    const handleBooksTab = useCallback((key)=>{
        const url = key === "aladinbook" ? BOOKS_LANDING : EBOOKS_LANDING;
        push(url, undefined, {
            ...routerOptions
        });
    }, [
        pathname,
        query,
        push
    ]);
    const handleSearch = (keyword)=>{
        if (!!keyword) {
            push({
                pathname,
                query: {
                    ...query,
                    [QueryKey.KEYWORD]: keyword
                }
            }, undefined, routerOptions);
            return;
        }
        searchParams.delete(QueryKey.KEYWORD);
        push({
            pathname,
            query: searchParams.toString()
        }, undefined, routerOptions);
    };
    const productType = useMemo(()=>{
        return isBooks ? "aladinbook" : "ebook";
    }, [
        isBooks
    ]);
    //getBooksProduct로 전송할 쿼리
    const getFilteredQuery = useMemo(()=>{
        const filteredQuery = {};
        searchParams.forEach((value, key)=>{
            if (Object.values(QueryKey).includes(key)) {
                filteredQuery[key] = value;
            }
        });
        return filteredQuery;
    }, [
        searchParams
    ]);
    return {
        handleDetailFilter,
        handleResetFilter,
        handleSearch,
        handleActiveTab: handleBooksTab,
        selectedFilterList,
        hasSelectedFilter,
        productType,
        keyword,
        query,
        getFilteredQuery,
        searchParams
    };
};
