import { create } from "zustand";
const useClassStore = create((set)=>({
        adding: {
            name: "",
            step: 0
        },
        selected: {},
        userSourceBooks: {},
        setAdding: (newValue)=>set((state)=>({
                    ...state,
                    adding: {
                        ...state.adding,
                        ...newValue
                    }
                })),
        openNewClass: (isWithSelected)=>set((state)=>({
                    ...state,
                    adding: {
                        ...state.adding,
                        open: true,
                        isWithSelected: !!isWithSelected
                    }
                })),
        closeNewClass: ()=>set((state)=>({
                    ...state,
                    adding: {
                        ...state.adding,
                        open: false
                    }
                })),
        setMine: (classes)=>set((state)=>({
                    ...state,
                    mine: classes
                })),
        setSelected: (selected)=>set((state)=>({
                    ...state,
                    selected
                })),
        toggleSelected: (param)=>{
            let { sourceId , sourceType  } = param;
            return set((state)=>{
                const result = {
                    ...state.selected
                };
                if (result[sourceId]) {
                    delete result[sourceId];
                } else {
                    result[sourceId] = sourceType;
                }
                return {
                    ...state,
                    selected: result
                };
            });
        },
        setUserSourceBooks: (userSourceBooks)=>set((state)=>({
                    ...state,
                    userSourceBooks: {
                        ...state.userSourceBooks,
                        ...userSourceBooks
                    }
                }))
    }));
export default useClassStore;
