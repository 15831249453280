import { _ as _tagged_template_literal } from "@swc/helpers/_/_tagged_template_literal";
function _templateObject() {
    const data = _tagged_template_literal([
        "\n  h3 {\n    color: ",
        ";\n    text-transform: capitalize;\n    font-size: 22px;\n    font-weight: 700;\n  }\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
const LogoArea = styled.div.withConfig({
    componentId: "sc-535ed6a3-0"
})(_templateObject(), themeGet("primary.0", "#008489"));
export default LogoArea;
