import React, { useCallback, useState } from "react";
export const useSubmit = ()=>{
    const [isSubmmiting, setSubmitting] = useState(false);
    const start = useCallback(()=>{
        setSubmitting(true);
    }, []);
    const done = useCallback(()=>{
        setSubmitting(false);
    }, []);
    return [
        isSubmmiting,
        start,
        done
    ];
};
export const useForm = (initialValue, param)=>{
    let { validator , onSubmit  } = param;
    const [values, setValues] = useState(initialValue);
    const [isSubmitting, start, done] = useSubmit();
    const onChange = useCallback((e)=>{
        const { name , value , type  } = e.target;
        if (type === "checkbox") {
            const { checked  } = e.target;
            setValues((prev)=>({
                    ...prev,
                    [name]: checked
                }));
        } else {
            setValues((prev)=>({
                    ...prev,
                    [name]: value
                }));
        }
    }, []);
    const setValue = useCallback((name, value)=>{
        setValues((prev)=>({
                ...prev,
                [name]: value
            }));
    }, []);
    const submit = useCallback(()=>{
        if (isSubmitting) {
            return;
        }
        if (onSubmit) {
            start();
            if (validator) {
                const [isValid, message] = validator(values);
                onSubmit(done, {
                    isValid,
                    message
                });
            } else {
                onSubmit(done, {
                    isValid: true
                });
            }
        }
    }, [
        isSubmitting,
        onSubmit,
        validator,
        values
    ]);
    const reset = useCallback(()=>{
        setValues(initialValue);
    }, [
        initialValue
    ]);
    return {
        values,
        setValues,
        setValue,
        onChange,
        submit,
        isSubmitting,
        isValidated: validator ? validator(values)[0] : true,
        reset
    };
};
