import { useSearchStore } from "@/modules/search/store/search.store";
import { CurriculumsWithString, DataTypesWithString, GradesWithString } from "../../api/product";
import { renderKoreanFilterName } from "@/components/Search";
// import { STUDIO_VERSION } from '@/modules/products/views/ProductDetailView'
export const mixpanelEvent = (event_name, props)=>{
    try {
        if (window.mixpanel) {
            window.mixpanel.track(event_name, props);
        }
    } catch (e) {
        ;
    }
};
//Signup login
export function signupCompleted(property) {
    if (property["Login Method"] === "phone") {
        try {
            if (window.mixpanel) {
                window.mixpanel.alias(property.user_id);
                window.mixpanel.track("Sign Up Completed");
            }
            //네이버
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "//wcs.naver.net/wcslog.js";
            document.body.appendChild(script);
            const wcsScript1 = document.createElement("script");
            wcsScript1.src = "//wcs.naver.net/wcslog.js";
            wcsScript1.async = true;
            wcsScript1.onload = function() {
                const nasaScript1 = document.createElement("script");
                nasaScript1.innerHTML = '\n          var _nasa = {};\n          if (window.wcs) _nasa["cnv"] = wcs.cnv("2","0");\n        ';
                document.head.appendChild(nasaScript1);
            };
            document.head.appendChild(wcsScript1);
        } catch (e) {
            ;
        }
    } else if (property["Login Method"] === "kakao") {
        try {
            if (window.mixpanel) {
                window.mixpanel.alias(property.user_id);
                window.mixpanel.track("Sign Up Completed");
            }
            //네이버
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "//wcs.naver.net/wcslog.js";
            document.body.appendChild(script);
            const wcsScript1 = document.createElement("script");
            wcsScript1.src = "//wcs.naver.net/wcslog.js";
            wcsScript1.async = true;
            wcsScript1.onload = function() {
                const nasaScript1 = document.createElement("script");
                nasaScript1.innerHTML = '\n          var _nasa = {};\n          if (window.wcs) _nasa["cnv"] = wcs.cnv("2","0");\n        ';
                document.head.appendChild(nasaScript1);
            };
            document.head.appendChild(wcsScript1);
        } catch (e) {
            ;
        }
    }
}
export function loginCompleted(property) {
    try {
        if (window.mixpanel) {
            window.mixpanel.identify(property.user_id) //distinct id 설정
            ;
            window.mixpanel.people.set_once({
                ...property
            });
            window.mixpanel.track("Login Completed");
        }
    } catch (e) {
        ;
    }
}
export function productDetailPageViewed(property) {
    var _property_source, _property_source1, _property_source2;
    const sourceType = property.source_type;
    const handout = property.source;
    const textbook = property.source;
    const isEditable = !!handout && handout.studio_version === "v2";
    const item = {
        "Product ID": property.id,
        Price: property.price,
        "Product Name": property.title,
        "Product Category": property.source_type,
        "Product Type": sourceType === "handout" ? DataTypesWithString[handout.category] : null,
        "Product Subject": (_property_source = property.source) === null || _property_source === void 0 ? void 0 : _property_source.subject,
        "Product Curriculum": CurriculumsWithString[(_property_source1 = property.source) === null || _property_source1 === void 0 ? void 0 : _property_source1.curriculum],
        "Product Grade": GradesWithString[(_property_source2 = property.source) === null || _property_source2 === void 0 ? void 0 : _property_source2.grade],
        "Product Author": property.author,
        "Product Publisher": sourceType === "handout" ? null : textbook === null || textbook === void 0 ? void 0 : textbook.publisher_id.toString(),
        "Product Based License": sourceType === "handout" ? handout === null || handout === void 0 ? void 0 : handout.based_textbook_title : null,
        "Product Based License Type": sourceType === "handout" ? handout === null || handout === void 0 ? void 0 : handout.base_source_type : null,
        "Parsing-badge": isEditable
    };
    try {
        if (window.mixpanel) {
            window.mixpanel.track("Product Detail Page Viewed", {
                ...item
            });
        }
    } catch (e) {
        ;
    }
}
export function productDetailTimeSpent() {
    try {
        if (window.mixpanel) {
            window.mixpanel.time_event("Product Detail Page time spent");
        }
    } catch (e) {
        ;
    }
}
export function noteTimeSpent() {
    try {
        if (window.mixpanel) {
            window.mixpanel.time_event("[Note] Session Length in Viewer");
        }
    } catch (e) {
        ;
    }
}
export function noteClassStartTimeSpent() {
    try {
        if (window.mixpanel) {
            window.mixpanel.time_event("[Note] Class Clicked");
        }
    } catch (e) {
        ;
    }
}
export function productAddedToCart(property) {
    var _property_source, _textbook_publisher_id;
    const sourceType = property.source_type;
    const handout = property.source;
    const textbook = property.source;
    const item = {
        "Product ID": property.id,
        // Price: property.property_variants.find((item) => item.options.label === '라이선스').price,
        "Product Name": property.title,
        "Product Category": property.source_type,
        "Product Type": sourceType === "handout" ? handout.category : null,
        "Product Subject": ((_property_source = property.source) === null || _property_source === void 0 ? void 0 : _property_source.subject) || property.subject,
        "Product Curriculum": sourceType === "handout" ? CurriculumsWithString[handout.curriculum] : null,
        "Product Grade": sourceType === "handout" ? GradesWithString[handout.grade] : null,
        "Product Author": property.author,
        "Product Publisher": sourceType === "handout" ? null : textbook === null || textbook === void 0 ? void 0 : (_textbook_publisher_id = textbook.publisher_id) === null || _textbook_publisher_id === void 0 ? void 0 : _textbook_publisher_id.toString(),
        "Product Based License": sourceType === "handout" ? handout.based_textbook_title : null,
        "Product Based License Type": sourceType === "handout" ? handout.base_source_type : null,
        "Order Type": "일반",
        "Product Variant": "라이선스",
        "Parsing Badge": sourceType === "handout" ? handout.studio_version === "v2" : null,
        "relative products": property.relativeProducts
    };
    try {
        if (window.mixpanel) {
            window.mixpanel.track("Product Added to Cart", {
                ...item
            });
        }
    } catch (e) {
        ;
    }
}
export function purchasedProduct(property) {
    //네이버
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "//wcs.naver.net/wcslog.js";
    document.body.appendChild(script);
    const wcsScript1 = document.createElement("script");
    wcsScript1.src = "//wcs.naver.net/wcslog.js";
    wcsScript1.async = true;
    wcsScript1.onload = function() {
        const nasaScript1 = document.createElement("script");
        nasaScript1.innerHTML = '\n          var _nasa = {};\n          if (window.wcs) _nasa["cnv"] = wcs.cnv("3","0");\n        ';
        document.head.appendChild(nasaScript1);
    };
    document.head.appendChild(wcsScript1);
    for(let i = 0; i < property.length; i++){
        try {
            if (window.mixpanel) {
                window.mixpanel.track("Purchased Product", {
                    "Product ID": property[i].product_id,
                    "Product Category": property[i].product.source_type,
                    "Product Name": property[i].product.title,
                    Price: property[i].price,
                    // 'Product Sourcce': property[i].product.,
                    "Product Author": property[i].product.author,
                    "Product Variant": property[i].product_variant.options.label,
                    "Order Type": "일반",
                    // @ts-ignore
                    "Source Product ID": property[i].source_product_id,
                    // @ts-ignore
                    "Source Product Title": property[i].source_product_title
                });
            }
        } catch (e) {
            ;
        }
    }
}
export function userLogout() {
    try {
        if (window.mixpanel) {
            window.mixpanel.reset();
            window.mixpanel.track("Logout");
        }
    } catch (e) {
        ;
    }
}
//productDetail
export const productDetailPageScrolled = (property)=>{
    try {
        if (window.mixpanel) {
            window.mixpanel.track("Product Detail Page Scrolled", {
                ...property
            });
        }
    } catch (e) {
        ;
    }
};
export const solvookPageViewed = (url)=>{
    try {
        if (window.mixpanel) {
            window.mixpanel.track("[Solvook] page Viewed", {
                url: url
            });
        }
    } catch (e) {
        ;
    }
};
export const searchBarEvent = (param)=>{
    let { keyword , search_type  } = param;
    // const totalCount = useSearchStore?.getState()?.totalCount || 0
    try {
        if (window.mixpanel) {
            window.mixpanel.track("Searched from Search-bar", {
                "Search Term": keyword,
                "Search Category": search_type
            });
        }
    } catch (e) {
        ;
    }
};
export const searchFilteredEvent = (param)=>{
    let { filterType , value  } = param;
    var _useSearchStore_getState;
    const totalCount = ((_useSearchStore_getState = useSearchStore === null || useSearchStore === void 0 ? void 0 : useSearchStore.getState()) === null || _useSearchStore_getState === void 0 ? void 0 : _useSearchStore_getState.totalCount) || 0;
    try {
        if (window.mixpanel) {
            window.mixpanel.track("Search Filtered", {
                "Filter Location": "keyword",
                "Filter Type": renderKoreanFilterName(filterType),
                "Filter Option Selected": value,
                "# of Results": totalCount
            });
        }
    } catch (e) {
        ;
    }
};
export const updateUserInfoMixpanel = (param)=>{
    let { userProfile , userId  } = param;
    try {
        if (window.mixpanel) {
            window.mixpanel.identify(userId);
            window.mixpanel.people.set_once({
                "User Type": userProfile.userType,
                "Main Subject": userProfile.subject,
                "School Name": userProfile.school.map((item)=>item.name).join(",")
            });
        }
    } catch (e) {
        ;
    }
};
