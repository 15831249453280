import { useMembershipStore } from "@/components/Package/PassSection/store/passStore";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { getHandoutCoupons } from "src/api/coupon";
const useCouponReceive = (param)=>{
    const { setState  } = useMembershipStore((state)=>({
            setState: state.setState
        }));
    const { data: handoutCoupons  } = useQuery([
        "getHandoutCoupons",
        param.id
    ], ()=>{
        return getHandoutCoupons({
            productId: String(param.id),
            productPrice: param.price
        });
    }, {
        retry: 3
    });
    const filteredHasCoupon = useCallback(()=>{
        if (!handoutCoupons || !handoutCoupons.coupons) return null;
        if (handoutCoupons.coupons.length) {
            return handoutCoupons.coupons.filter((coupon)=>!coupon.hasBeenReceived);
        }
    }, [
        handoutCoupons
    ]);
    useEffect(()=>{
        if (handoutCoupons) {
            setState("handoutCoupons", handoutCoupons.coupons);
        }
    }, [
        handoutCoupons
    ]);
    return {
        handoutCoupons,
        filteredHasCoupon
    };
};
export default useCouponReceive;
