import { otherApiClient } from "./index";
import { toQueryStringCamel } from "../utils/misc";
import { CurriculumsWithString, SubjectsWithString } from "./product";
export const findLabel = (key, value)=>{
    switch(key){
        case QueryKey.CURRICULUM:
            return CurriculumsWithString[value];
        case QueryKey.SUBJECT:
            return SubjectsWithString[value];
        case QueryKey.KEYWORD:
            return "검색 : ".concat(value);
        default:
            return value;
    }
};
export const BookListDetailFiltersMap = {
    publisher: {
        label: "출판사",
        colSpan: 12
    },
    curriculum: {
        label: "학년",
        colSpan: 12
    },
    subject: {
        label: "과목",
        colSpan: 12
    }
};
export const QueryKey = {
    PRODUCT_TYPE: "productType",
    KEYWORD: "q",
    SORT_KEY: "sortkey",
    SORT_DIRECTION: "sortDirection",
    SUBJECT: "subjects[]",
    CURRICULUM: "curriculums[]",
    PUBLISHER: "publishers[]",
    GRADE: "grades[]",
    DEPARTMENT: "departments[]",
    TYPE: "types[]"
};
export const FilterTypeToQueryKey = {
    subject: QueryKey.SUBJECT,
    curriculum: QueryKey.CURRICULUM,
    publisher: QueryKey.PUBLISHER
};
export const EbookFilterTypeToQueryKey = {
    department: QueryKey.DEPARTMENT,
    type: QueryKey.TYPE
};
export const isBooksProduct = (product)=>{
    if (typeof product !== "object") return false;
    return [
        "author",
        "isbn13",
        "originPrice",
        "productType",
        "provider",
        "publishedAt"
    ].every((key)=>key in product);
};
export const getBooksProductsFilters = async (params)=>{
    const { data  } = await otherApiClient.market("/products/filters/".concat(toQueryStringCamel(params)));
    return data;
};
export const getBooksProducts = async (params)=>{
    const { data  } = await otherApiClient.market("/products/".concat(toQueryStringCamel(params), "  "));
    return data;
};
export const getBooksProductsCount = async (productType)=>{
    const { data  } = await otherApiClient.market("/products/count/".concat(toQueryStringCamel({
        productType: productType
    }), "  "));
    return data;
};
export const getBooksProduct = async (params)=>{
    const { data  } = await otherApiClient.market("/products/".concat(params.id, "/").concat(toQueryStringCamel({
        productType: params.productType
    })));
    return data;
};
