import { numberFormatter } from "@/lib/utils";
import { PassCategory } from "src/api/membership";
import { SourceTypeWithString } from "src/api/product";
export const isCategoryIncludesExpert = (category)=>{
    return category.includes(PassCategory.Expert);
};
export const getPassScope = (type)=>{
    switch(type.category){
        case PassCategory.Subscription:
            return [
                {
                    type: "과목",
                    value: type.availableSubjectNames
                },
                {
                    type: "과정",
                    value: type.availableCurriculumNames
                },
                {
                    type: type.unavailableAuthorNames ? "제외 저자" : "저자",
                    value: type.unavailableAuthorNames ? type.unavailableAuthorNames : type.availableAuthorNames
                },
                {
                    type: type.unavailablePublisherNames ? "제외 출판사" : "출판사",
                    value: type.unavailablePublisherNames ? type.unavailablePublisherNames : type.availablePublisherNames
                },
                {
                    type: "가격",
                    value: "".concat(numberFormatter(type.availableMinPrice), "원~\n								").concat(numberFormatter(type.availableMaxPrice), "원 사이의 수업자료")
                },
                {
                    type: "상품종류",
                    value: SourceTypeWithString["handout"]
                }
            ];
        case PassCategory.Solvookplus:
        case PassCategory.Ticket:
            return [
                {
                    type: "과목",
                    value: type.availableSubjectNames
                },
                {
                    type: "과정",
                    value: type.availableCurriculumNames
                },
                {
                    type: type.unavailableAuthorNames ? "제외 저자" : "저자",
                    value: type.unavailableAuthorNames ? type.unavailableAuthorNames : type.availableAuthorNames
                },
                {
                    type: type.unavailablePublisherNames ? "제외 출판사" : "출판사",
                    value: type.unavailablePublisherNames ? type.unavailablePublisherNames : type.availablePublisherNames
                },
                {
                    type: "가격",
                    // value: type.availableMaxPrice
                    // 	? '없음'
                    // 	: `${numberFormatter(type.availableMinPrice)}원~
                    // 			${numberFormatter(type.availableMaxPrice)}원 사이의 수업자료`,
                    value: "".concat(numberFormatter(type.availableMinPrice), "원~\n								").concat(numberFormatter(type.availableMaxPrice), "원 사이의 수업자료")
                },
                {
                    type: "상품종류",
                    value: SourceTypeWithString["handout"]
                }
            ];
        case PassCategory.Expert:
            return [
                {
                    type: "과목",
                    value: type.availableSubjectNames
                },
                {
                    type: "과정",
                    value: type.availableCurriculumNames
                },
                {
                    type: "출판사",
                    value: type.availablePublisherNames
                },
                {
                    type: "상품종류",
                    value: "엑스퍼트"
                }
            ];
        case PassCategory.ExpertSubscription:
            return [
                {
                    type: "과목",
                    value: type.availableSubjectNames
                },
                {
                    type: "과정",
                    value: type.availableCurriculumNames
                },
                {
                    type: "출판사",
                    value: type.availablePublisherNames
                },
                {
                    type: "상품종류",
                    value: "엑스퍼트"
                }
            ];
        default:
            //ticket 타입
            return [
                {
                    type: "과목",
                    value: type.availableSubjectNames
                },
                {
                    type: "과정",
                    value: type.availableCurriculumNames
                },
                {
                    type: type.unavailableAuthorNames ? "제외 저자" : "저자",
                    value: type.unavailableAuthorNames ? type.unavailableAuthorNames : type.availableAuthorNames
                },
                {
                    type: type.unavailablePublisherNames ? "제외 출판사" : "출판사",
                    value: type.unavailablePublisherNames ? type.unavailablePublisherNames : type.availablePublisherNames
                },
                {
                    type: "가격",
                    value: "".concat(numberFormatter(type.availableMinPrice), "원~\n								").concat(numberFormatter(type.availableMaxPrice), "원 사이의 수업자료")
                },
                {
                    type: "상품종류",
                    value: SourceTypeWithString["handout"]
                }
            ];
    }
};
