export const includesPath = (path, target)=>{
    // 연구소 페이지 [] 텍스트로 인해 수정
    const escapedTarget = target.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
    // return !!new RegExp(`^${target}`).exec(path)
    return !!new RegExp("^".concat(escapedTarget)).exec(path);
};
export const toQueryString = function(obj) {
    let withQuerySymbol = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true;
    if (obj === null) {
        return "";
    }
    const query = Object.keys(obj).filter((key)=>key !== "" && obj[key] !== undefined && obj[key] !== "").map((key)=>"".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(obj[key]))).join("&");
    if (!query) {
        return "";
    }
    return withQuerySymbol ? "?".concat(query) : query;
};
export const getUrlStringToParams = (url)=>{
    const params = {};
    const urlSplit = url.split("?");
    if (urlSplit.length > 1) {
        const paramsSplit = urlSplit[1].split("&");
        paramsSplit.forEach((param)=>{
            const paramSplit = param.split("=");
            params[paramSplit[0]] = paramSplit[1];
        });
    }
    return params;
};
