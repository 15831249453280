import numeral from "numeral";
import dayjs from "dayjs";
export var PhoneType;
(function(PhoneType) {
    PhoneType[PhoneType["default"] = 0] = "default";
})(PhoneType || (PhoneType = {}));
export const priceFormatter = (price)=>{
    return numeral(price).format("0,0");
};
export const priceWithStringFormatter = (price)=>{
    return numeral(price).format("0,0") + "원";
};
export const numberFormatter = (price)=>{
    return numeral(price).format("0,0");
};
export const dateFormatter = (date)=>{
    return dayjs(date).format("YYYY. MM. DD. A HH:mm");
};
export const dateTransFormatter = (date)=>{
    return dayjs(date).format("YYYY. MM. DD");
};
export function phoneFormatter(num) {
    let type = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : PhoneType.default;
    var formatNum = "";
    if (num.length === 11) {
        if (type === 0) {
            formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-****-$3");
        } else {
            formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        }
    } else if (num.length === 8) {
        formatNum = num.replace(/(\d{4})(\d{4})/, "$1-$2");
    } else {
        if (num.indexOf("02") === 0) {
            if (type === 0) {
                formatNum = num.replace(/(\d{2})(\d{3,4})(\d{4})/, "$1-****-$3");
            } else {
                formatNum = num.replace(/(\d{2})(\d{3,4})(\d{4})/, "$1-$2-$3");
            }
        } else {
            if (type === 0) {
                formatNum = num.replace(/(\d{3})(\d{3,4})(\d{4})/, "$1-***-$3");
            } else {
                formatNum = num.replace(/(\d{3})(\d{3,4})(\d{4})/, "$1-$2-$3");
            }
        }
    }
    return formatNum;
}
