import { create } from "zustand";
import { devtools } from "zustand/middleware";
export const useSideCategoryMenuStore = create()(devtools((set, get)=>({
        isOpenSideMenu: false,
        isOpenMobileSideMenu: false,
        currentDepth: "",
        twoDepthId: "",
        threeDepthId: "",
        fourDepthId: "",
        closeSideMenu: ()=>set({
                isOpenSideMenu: false,
                isOpenMobileSideMenu: false,
                currentDepth: "",
                twoDepthId: "",
                threeDepthId: "",
                fourDepthId: ""
            }),
        openSideMenu: ()=>set({
                isOpenSideMenu: true
            }),
        openMobileSideMenu: ()=>set({
                isOpenMobileSideMenu: true
            }),
        setCurrentDepth: (depthId)=>set({
                currentDepth: depthId
            }),
        setInitDepth: ()=>set({
                threeDepthId: ""
            }),
        setState: (key, param)=>{
            set((state)=>({
                    ...state,
                    [key]: param
                }));
        }
    })));
