import { useEffect, useState } from "react";
import { getCookie, setCookie } from "cookies-next";
export const useTooltip = (param)=>{
    let { cookieName , day  } = param;
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const handleTooltip = ()=>{
        if (!isTooltipVisible) return;
        setIsTooltipVisible(false);
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + day);
        setCookie(cookieName, "false", {
            expires: tomorrow
        });
    };
    useEffect(()=>{
        const tooltipCookie = getCookie(cookieName);
        if (tooltipCookie !== "false") {
            setIsTooltipVisible(true);
        }
    }, []);
    return {
        isTooltipVisible,
        handleTooltip
    };
};
