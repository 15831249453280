import { create } from "zustand";
const LoginDialog = create((set)=>({
        isOpenLoginDialog: false,
        closeLoginDialog: ()=>set({
                isOpenLoginDialog: false
            }),
        openLoginDialog: ()=>set({
                isOpenLoginDialog: true
            }),
        isOpenSignUpDialog: false,
        openSignUpDialogContents: {
            title: "",
            content: "",
            btnLabel: ""
        },
        setState: (key, param)=>{
            set((state)=>({
                    ...state,
                    [key]: param
                }));
        }
    }));
export default LoginDialog;
