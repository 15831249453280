import { useRouter } from "next/router";
import { useMemo } from "react";
const useSearchParams = ()=>{
    const { asPath  } = useRouter();
    return useMemo(()=>{
        const splitedPath = asPath.split("?");
        const searchParams = new URLSearchParams(splitedPath.length > 1 ? splitedPath[1] : undefined);
        return searchParams;
    }, [
        asPath
    ]);
};
export default useSearchParams;
