import { _ as _tagged_template_literal } from "@swc/helpers/_/_tagged_template_literal";
function _templateObject() {
    const data = _tagged_template_literal([
        "\n\nspan {\n	color: inherit;\n}\n\nbody {\n   //-webkit-user-select: none;\n   //-webkit-tap-highlight-color: transparent;\n   //-webkit-touch-callout: none;\n	padding-top: constant(safe-area-inset-top); /* iOS 11.0 */\n	padding-top: env(safe-area-inset-top); /* iOS 11.2 */\n}\n    \n.ant-form-item {\nmargin: 0 0 16px;\n\n .ant-form-explain, .ant-form-extra {\n	margin-top: 2px;\n	line-height: 26px;   		\n  } \n}\n\n.active-link {\n	display: inline-block;\n	height: 64px;\n}\n\n .am-tabs-default-bar-tab-active {\n 	color: ",
        ";\n } \n \n .am-tabs-default-bar-underline {\n 	border: 1px ",
        " solid;\n }\n//\n// .ant-checkbox {\n// 	flex-shrink: 0;\n// 	.ant-checkbox-inner {\n// 	  border-radius: 0;\n// 	  border-color: ",
        ";\n// 	}\n//     &.ant-checkbox-checked {\n// 	  .ant-checkbox-inner {\n// 	     border-color: ",
        ";\n// 		 background-color: ",
        ";\n// 	   }\n//     }\n// 	&::after {\n// 		display: none;\n// 	}\n// }  \n// "
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
import { createGlobalStyle } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
//less
export const AppDefaultStyle = createGlobalStyle(_templateObject(), themeGet("primary.0"), themeGet("primary.0"), themeGet("text.1", "#909090"), themeGet("primary.0", "#008489"), themeGet("primary.0", "#008489"));
