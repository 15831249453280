import camelize from "camelize";
import { useCallback, useEffect, useRef, useState } from "react";
import snakeize from "snakeize";
//스네이크 쿼리스트링
export const toQueryString = function(obj) {
    let withQuerySymbol = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true, transSnake = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    if (obj === null) {
        return "";
    }
    const snakeObj = transSnake ? snakeize(obj) : obj;
    const query = Object.keys(snakeObj).filter((key)=>key !== "" && snakeObj[key] !== undefined && snakeObj[key] !== "").map((key)=>"".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(snakeObj[key]))).join("&");
    if (!query) {
        return "";
    }
    return withQuerySymbol ? "?".concat(query) : query;
};
//카멜 쿼리스트링
export const toQueryStringCamel = function(obj) {
    let withQuerySymbol = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true, transSnake = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    if (obj === null) {
        return "";
    }
    const query = Object.keys(obj).filter((key)=>key !== "" && obj[key] !== undefined && obj[key] !== "" && obj[key] !== null).map((key)=>{
        if (Array.isArray(obj[key])) {
            return obj[key].map((value)=>"".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(value))).join("&");
        }
        return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(obj[key]));
    }).join("&");
    if (!query) {
        return "";
    }
    return withQuerySymbol ? "?".concat(query) : query;
};
export const getParams = (query)=>{
    if (!query) {
        return {};
    }
    return camelize((/^[?#]/.test(query) ? query.slice(1) : query).split("&").filter((key)=>key !== "").reduce((params, param)=>{
        const pair = param.split("=");
        const key = pair[0];
        let value = pair[1];
        // value = value.indexOf(',') > -1 ? value.split(',') : value;
        value = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
        params[key] = value // eslint-disable-line
        ;
        return params;
    }, {}));
};
export function useMounted() {
    const [mounted, setMounted] = useState(false);
    useEffect(()=>{
        setMounted(true);
    }, []);
    return mounted;
}
export function useDelayedState(initialValue) {
    let delay = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 200;
    const [value, setValue] = useState(initialValue);
    const timeout = useRef(0);
    const setDelayValue = useCallback((value)=>{
        timeout.current = window.setTimeout(()=>{
            setValue(value);
        }, delay);
    }, [
        delay
    ]);
    useEffect(()=>{
        return ()=>{
            clearTimeout(timeout.current);
        };
    }, []);
    return [
        value,
        setValue,
        setDelayValue
    ];
}
export const lockScroll = ()=>{
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = "0px";
};
export const unlockScroll = ()=>{
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
};
export const isInWebview = ()=>"object" !== "undefined" && !!window["mobileWebview"];
