import { useCallback, useEffect, useMemo, useState } from "react";
import { Subjects } from "../../api/product";
import { useQuery } from "@tanstack/react-query";
import { defaultSearchType, getSearchTerms } from "../../api/search";
import { useAuthStore } from "@/lib/auth/AuthStore";
import { useRouter } from "next/router";
import { PRODUCT_SEARCH_PAGE } from "@/settings/constant";
import { mixpanelEvent, searchBarEvent } from "@/lib/mixpanels/mixpanel";
export const useSearchWithSearchDialog = ()=>{
    const { isLoggedIn , user  } = useAuthStore();
    const router = useRouter();
    const { query , push , pathname  } = router;
    const { q: keyword  } = query;
    const [isOpenSearchDialog, setIsOpenSearchDialog] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState((user === null || user === void 0 ? void 0 : user.mainSubject) || Subjects.EN);
    const [searchTermValue, setSearchTermValue] = useState("");
    const defaultTerms = {
        subject: null,
        placeHolder: "찾고 싶은 자료를 검색해보세요",
        terms: []
    };
    const { data: getSearchTermsResponse  } = useQuery([
        "getSearchTerms"
    ], getSearchTerms);
    const userMainSubject = useMemo(()=>{
        return (user === null || user === void 0 ? void 0 : user.mainSubject) || Subjects.EN;
    }, [
        user
    ]);
    const recommendSearchTermList = useMemo(()=>{
        return (getSearchTermsResponse === null || getSearchTermsResponse === void 0 ? void 0 : getSearchTermsResponse.termItems.find((item)=>{
            return (item === null || item === void 0 ? void 0 : item.subject) === selectedSubject;
        })) || defaultTerms;
    }, [
        getSearchTermsResponse,
        selectedSubject,
        userMainSubject
    ]);
    const onClearAll = ()=>{
        setSelectedSubject(userMainSubject);
        setSearchTermValue("");
    };
    const openSearchDialog = ()=>{
        //검색후 검색페이지에서 모달을 열었을때 검색어와 과목이  유지되어야함.
        if (keyword && pathname === PRODUCT_SEARCH_PAGE) {
            setSearchTermValue(keyword);
            setSelectedSubject(query.subject || userMainSubject);
        }
        setIsOpenSearchDialog(true);
        mixpanelEvent("Search Modal Clicked", {
            Subject: selectedSubject,
            Type: "Market",
            "Placeholder Text": recommendSearchTermList === null || recommendSearchTermList === void 0 ? void 0 : recommendSearchTermList.placeHolder
        });
        mixpanelEvent("GNB button clicked", {
            position: "market",
            "button type": "search bar",
            "button name": "검색창"
        });
    };
    const closeSearchDialog = useCallback(()=>{
        setIsOpenSearchDialog(false);
    }, []);
    const onClearSearchTerm = useCallback(()=>{
        setSearchTermValue("");
    }, []);
    const onChangeSearchTerm = useCallback((e)=>{
        setSearchTermValue(e.target.value);
    }, []);
    const handleSelectedSubject = useCallback((subject)=>{
        setSelectedSubject(subject);
    }, []);
    const navigateToSearch = useCallback((keyword)=>{
        const query = {
            q: keyword,
            subject: selectedSubject
        };
        push({
            pathname: PRODUCT_SEARCH_PAGE,
            query: {
                ...query
            }
        });
    }, [
        router,
        selectedSubject
    ]);
    const currentSearchTerm = useMemo(()=>{
        return pathname === PRODUCT_SEARCH_PAGE && keyword || "";
    }, [
        keyword,
        pathname
    ]);
    const onSelectRecommendSearchTerm = useCallback((keyword)=>{
        navigateToSearch(keyword);
        closeSearchDialog();
    }, [
        selectedSubject
    ]);
    const onSubmitSearchTerm = useCallback(()=>{
        searchBarEvent({
            keyword: searchTermValue,
            search_type: defaultSearchType
        });
        navigateToSearch(searchTermValue);
        closeSearchDialog();
    }, [
        searchTermValue,
        selectedSubject
    ]);
    useEffect(()=>{
        setSelectedSubject(userMainSubject);
    }, [
        userMainSubject
    ]);
    useEffect(()=>{
        //페이지 이동시에는 초기화 되어야함.
        onClearAll();
    }, [
        pathname
    ]);
    return {
        openSearchDialog,
        recommendSearchTermList,
        currentSearchTerm,
        isOpenSearchDialog,
        closeSearchDialog,
        searchTermValue,
        onClearSearchTerm,
        onChangeSearchTerm,
        handleSelectedSubject,
        selectedSubject,
        onSubmitSearchTerm,
        onSelectRecommendSearchTerm
    };
};
