import { useCallback, useEffect } from "react";
import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { useOverlayStore } from "@/components/common/Overlay";
export const useModalStore = create((set)=>({
        map: new Map(),
        setModal: (name)=>(state)=>{
                set((prev)=>{
                    const prevState = prev.map.get(name);
                    return {
                        map: new Map(prev.map).set(name, {
                            ...prevState,
                            ...state
                        }),
                        current: name
                    };
                });
            },
        current: undefined,
        setCurrent: (name)=>{
            set({
                current: name
            });
        }
    }));
export function useModal(name) {
    let { initialOpen =false , routeChange =true , trigger =true , onClose  } = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var _state_map_get;
    const [{ isOpen , meta  }, setModal, setCurrent] = useModalStore((state)=>[
            (_state_map_get = state.map.get(name)) !== null && _state_map_get !== void 0 ? _state_map_get : {
                isOpen: initialOpen
            },
            state.setModal(name),
            state.setCurrent
        ]);
    const [openOverlay, closeOverlay] = useOverlayStore((state)=>[
            state.open,
            state.close
        ], shallow);
    useEffect(()=>{
        if (!trigger) {
            return;
        }
        if (isOpen) {
            openOverlay({
                name,
                routeChange,
                onClose: ()=>{
                    setModal({
                        isOpen: false
                    });
                    onClose === null || onClose === void 0 ? void 0 : onClose();
                }
            });
        } else {
            setCurrent(undefined);
        }
    }, [
        isOpen,
        trigger
    ]);
    const open = useCallback((meta)=>{
        setModal({
            isOpen: true,
            meta
        });
    }, []);
    return [
        isOpen,
        {
            open,
            close: closeOverlay
        },
        meta
    ];
}
