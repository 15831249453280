import { create } from "zustand";
const useHandoutProductStore = create((set)=>{
    return {
        currentProduct: null,
        hasAlreadyOwned: false,
        hasReview: true,
        relatedProducts: [],
        isOpenPassSectionModal: false,
        purchaseProductsInfo: {
            totalCount: 0,
            totalPrice: 0,
            selectedRelatedProducts: []
        },
        membershipAvailable: {
            usable: [],
            unusable: []
        },
        isOpenRelatedProductPreviewModal: false,
        selectedPreviewProduct: null,
        rating: 0,
        //상품페이지가 바뀌었을땐 연관교재 초기화
        updateCurrentProduct: (product)=>set(()=>({
                    currentProduct: product,
                    purchaseProductsInfo: {
                        totalCount: 1,
                        totalPrice: product.price,
                        selectedRelatedProducts: []
                    }
                })),
        //연과교재 업데이트시 선택상품 초기화
        updateRelatedProducts: (products)=>{
            return set((state)=>{
                var _state_currentProduct;
                if (products.length === 0) return {
                    relatedProducts: []
                };
                return {
                    relatedProducts: products,
                    purchaseProductsInfo: {
                        totalCount: state.currentProduct ? 1 : 0,
                        totalPrice: ((_state_currentProduct = state.currentProduct) === null || _state_currentProduct === void 0 ? void 0 : _state_currentProduct.price) || 0,
                        selectedRelatedProducts: []
                    }
                };
            });
        },
        toggleRelatedProductSelected: (product)=>set((state)=>{
                const find = state.purchaseProductsInfo.selectedRelatedProducts.find((item)=>item.id === product.id);
                const newSelectedBookList = find ? state.purchaseProductsInfo.selectedRelatedProducts.filter((item)=>item.id !== product.id) : [
                    ...state.purchaseProductsInfo.selectedRelatedProducts,
                    product
                ];
                return {
                    purchaseProductsInfo: {
                        totalCount: newSelectedBookList.length + 1,
                        totalPrice: newSelectedBookList.reduce((acc, cur)=>acc + cur.price, 0) + state.currentProduct.price,
                        selectedRelatedProducts: newSelectedBookList
                    }
                };
            }),
        updatePurchaseProductsInfo: (info)=>set(()=>{
                return {
                    purchaseProductsInfo: info
                };
            }),
        updateMembershipAvailable: (unUsableProductIds)=>{
            set((state)=>{
                const usable = state.purchaseProductsInfo.selectedRelatedProducts.filter((product)=>!unUsableProductIds.includes(product.id));
                const unusable = state.purchaseProductsInfo.selectedRelatedProducts.filter((product)=>unUsableProductIds.includes(product.id));
                return {
                    membershipAvailable: {
                        usable,
                        unusable
                    }
                };
            });
        },
        openPassSectionModal: ()=>set(()=>({
                    isOpenPassSectionModal: true
                })),
        closePassSectionModal: ()=>set(()=>({
                    isOpenPassSectionModal: false
                })),
        openRelatedProductPreviewModal: (product)=>set(()=>({
                    isOpenRelatedProductPreviewModal: true,
                    selectedPreviewProduct: product
                })),
        closeRelatedProductPreviewModal: ()=>set(()=>({
                    isOpenRelatedProductPreviewModal: false,
                    selectedPreviewProduct: null
                })),
        setHasAlreadyOwned: (hasAlreadyOwned)=>set(()=>({
                    hasAlreadyOwned
                })),
        setHasReview: (hasReview)=>set(()=>({
                    hasReview
                })),
        setRating: (rating)=>set(()=>({
                    rating
                }))
    };
});
export default useHandoutProductStore;
