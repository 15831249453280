import dayjs from "dayjs";
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const gaPageView = (url)=>{
    window.gtag("config", GA_TRACKING_ID, {
        page_path: url
    });
};
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gaEvent = (param)=>{
    let { action , category , label , value  } = param;
    if (!window.gtag) return console.log("no gtag");
    window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value
    });
};
export const gaAddToCart = (param)=>{
    let { id , name , price , quantity =1 , currency ="KRW"  } = param;
    window.gtag("event", "add_to_cart", {
        currency,
        value: price,
        items: [
            {
                item_id: id,
                item_name: name,
                index: 0,
                // item_brand: 'Google',
                // item_category: 'Apparel',
                // item_category2: 'Adult',
                // item_category3: 'Shirts',
                // item_category4: 'Crew',
                // item_category5: 'Short sleeve',
                // item_list_id: 'related_products',
                // item_list_name: 'Related Products',
                // item_variant: 'green',
                // location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
                price: price,
                quantity
            }
        ]
    });
};
export const gaViewItem = (param)=>{
    let { id , name , price , quantity =1 , currency ="KRW"  } = param;
    if (!window.gtag) return console.log("no gtag");
    window.gtag("event", "view_item", {
        currency,
        value: price,
        items: [
            {
                item_id: id,
                item_name: name,
                index: 0,
                // item_brand: 'Google',
                // item_category: 'Apparel',
                // item_category2: 'Adult',
                // item_category3: 'Shirts',
                // item_category4: 'Crew',
                // item_category5: 'Short sleeve',
                // item_list_id: 'related_products',
                // item_list_name: 'Related Products',
                // item_variant: 'green',
                // location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
                price: price,
                quantity
            }
        ]
    });
};
export const gaBeginCheckout = (param)=>{
    let { items , currency , value  } = param;
    window.gtag("event", "begin_checkout", {
        currency: currency,
        value: value,
        items: items
    });
};
export const gaPurchase = (param)=>{
    let { items , currency , value , transaction_id , paymethod  } = param;
    window.gtag("event", "purchase", {
        currency: currency,
        value: value,
        transaction_id: transaction_id,
        paymethod: paymethod,
        items: items
    });
};
export const gaViewItemList = (param)=>{
    let { item_list_id , item_list_name , items  } = param;
    window.gtag("event", "view_item_list", {
        item_list_id: item_list_id,
        item_list_name: item_list_name,
        items: items
    });
};
export const gaSignupCompleted = (property)=>{
    const date = property.registration_date;
    const created_at = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    const today = dayjs().format("YYYY-MM-DD HH:mm:ss");
    const diffMinute = dayjs(today).diff(created_at, "minute");
    if (property["type"] === "phone") {
        window.gtag("event", "sign_up", {
            method: "phone",
            name: property.name,
            user_id: property.user_id
        });
    } else if (property["type"] === "kakao" && diffMinute < 1) {
        window.gtag("event", "sign_up", {
            method: "kakao",
            name: property.name,
            user_id: property.user_id
        });
    }
};
