import { useQuery } from "@tanstack/react-query";
import { otherApiClient } from ".";
import { useAuthStore } from "@/lib/auth/AuthStore";
export const fetchBooksCart = async (params)=>{
    const { data  } = await otherApiClient.market("/carts/current", {
        params
    });
    return data;
};
export const fetchBooksCartAllForCounting = async ()=>{
    const caryTypes = [
        "aladinbook",
        "ebook"
    ];
    const results = await Promise.all(caryTypes.map((cartType)=>otherApiClient.market("/carts/current", {
            params: {
                cartType
            }
        })));
    return results.reduce((acc, cur)=>acc + cur.data.lineItems.length, 0);
};
export const postBooksCartItem = async (params)=>{
    const { data  } = await otherApiClient.market.post("/carts/current/items", params);
    return data;
};
export const deleteBooksCart = async (param)=>{
    let { lineItemId  } = param;
    const { data  } = await otherApiClient.market.delete("/carts/current/items/".concat(lineItemId));
    return data;
};
export const putBooksCart = async (param)=>{
    let { lineItemId , ...params } = param;
    const { data  } = await otherApiClient.market.put("/carts/current/items/".concat(lineItemId), params);
    return data;
};
export const getCurrentCartQuery = (param)=>{
    let { enabled , params  } = param;
    const { isLoggedIn  } = useAuthStore();
    return useQuery([
        "currentCart",
        params
    ], ()=>fetchBooksCart(params), {
        enabled: enabled !== null && enabled !== void 0 ? enabled : isLoggedIn
    });
};
