import { api, otherApiClient } from ".";
export const SourceTypeWithString = {
    textbook: "교과서 라이선스",
    workbook: "참고서 라이선스",
    handout: "수업자료",
    work: "문학 라이선스",
    all: "전체",
    aladinbook: "도서",
    ebook: "굿노트6 eBook",
    package: "이용권",
    ebookUniv: "굿노트6 대학생용 eBook"
};
// EN : 영어, KO : 한국어, MA : 수학, SC : 과학, SO : 사회, SL : 제2외국어, KH : 한국사, ET : 기타
export const Subjects = {
    EN: "EN",
    KO: "KO",
    MA: "MA",
    SO: "SO",
    SC: "SC",
    SL: "SL",
    KH: "KH",
    HI: "HI",
    ET: "ET"
};
export function isSubjects(value) {
    return !!Object.values(Subjects).find((v)=>v === value);
}
//구매가능한 과목 리스트 (검색, 과목탭에서 사용)
export const purchasableSubjectsList = ()=>{
    return [
        Subjects.EN,
        Subjects.KO,
        Subjects.MA,
        Subjects.SC,
        Subjects.SO,
        Subjects.HI
    ].map((subject)=>{
        return {
            key: subject,
            label: SubjectsWithString[subject]
        };
    });
};
export const DataTypesWithString = {
    10: "기출예상문제",
    20: "단계별복습",
    30: "변형문제",
    40: "본문분석",
    50: "워크북",
    60: "작품분석",
    70: "어법어휘",
    80: "예상문제",
    90: "모의고사",
    100: "개념완성",
    110: "유형훈련",
    120: "직보/파이널",
    130: "개념정리"
};
export const CurriculumsWithString = {
    10: "초등",
    20: "중등",
    30: "고등",
    40: "대학생",
    null: ""
};
export const GradesWithString = {
    // 10 : 1학년, 20 : 2학년, 30 : 3학년, 40 : 4학년, 50 : 5학년 , 60 : 6학년
    10: "1학년",
    20: "2학년",
    30: "3학년",
    40: "4학년",
    50: "5학년",
    60: "6학년",
    null: ""
};
export const SubjectsWithString = {
    EN: "영어",
    KO: "국어",
    MA: "수학",
    SO: "사회",
    SC: "과학",
    SL: "제2외국어",
    KH: "한국사",
    HI: "역사",
    ET: "기타",
    ALL: "전과목",
    null: ""
};
export const ContentTypesWithString = {
    11: "어휘 정리",
    12: "문법 정리",
    13: "표현 정리",
    14: "이해추론 정리",
    21: "어휘 훈련",
    22: "문법 훈련",
    23: "표현 훈련",
    24: "이해추론 훈련",
    31: "어휘 문제풀이",
    32: "문법 문제풀이",
    33: "표현 문제풀이",
    34: "이해추론 문제풀이"
};
export const getProduct = async (query)=>{
    const { data  } = await api.get("/products/".concat(query.id));
    return data;
};
export const postUseMembership = async (param)=>{
    const { data  } = await otherApiClient.market.post("/products/use-as-membership", {
        membershipIdToProductIds: {
            [param.membershipId]: param.productIds
        }
    });
    return data;
};
export const getRelatedProducts = async (id)=>{
    const { data  } = await otherApiClient.market("/products/".concat(id, "/relation"));
    return data;
};
export const getMembershipAvailable = async (param)=>{
    let { membershipId , productIds  } = param;
    const { data  } = await otherApiClient.market.post("/products/membership-available", {
        membershipIdToProductIds: {
            [membershipId]: productIds
        }
    });
    return data;
};
