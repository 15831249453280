import { redirect } from "./index";
import { DEFAULT_SESSION_COOKIE_NAME, DEFAULT_USER_COOKIE_NAME, getCookie } from "../sessions";
export function isAuthenticated(context) {
    const token = getCookie(DEFAULT_SESSION_COOKIE_NAME, context.req);
    const isLoggedIn = !!token;
    if (isLoggedIn) {
        redirect("/", context);
    }
    return {
        isLoggedIn
    };
}
export function secretPage(context) {
    const token = getCookie(DEFAULT_SESSION_COOKIE_NAME, context.req);
    const isLoggedIn = !!token;
    if (!isLoggedIn) {
        redirect("/login", context);
    }
    return {
        isLoggedIn
    };
}
export function withData(context) {
    const token = getCookie(DEFAULT_SESSION_COOKIE_NAME, context.req);
    const isLoggedIn = !!token;
    const isUser = getCookie(DEFAULT_USER_COOKIE_NAME, context.req);
    const userCookie = isUser ? JSON.parse(isUser) : {};
    const user = userCookie ? userCookie : {};
    return {
        user,
        isLoggedIn
    };
}
