import { useSignout } from "@/containers/Header/LoginMenu";
import apiService from "@/lib/apiService/apiService";
import { useAuthStore } from "@/lib/auth/AuthStore";
import { mixpanelEvent } from "@/lib/mixpanels/mixpanel";
import { redirect } from "@/lib/next-utils";
import { LogoutCommand } from "@/modules/accounts/commands/logoutCommand";
import { HOME_PAGE } from "@/settings/constant";
import { useCallback, useMemo } from "react";
import { useSavedUserSourceStore } from "../../../store/main";
import { isInWebview } from "../../../utils/misc";
import { useSideCategoryMenuStore } from "../../SideSheets/store/useCategoryMenuStore";
const useLogout = function() {
    let { redirectPath =HOME_PAGE  } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const { user , logout , isLoggedIn  } = useAuthStore();
    const { postProcessForKakao  } = useSignout();
    const { closeSideMenu  } = useSideCategoryMenuStore();
    const { updateUserMeta  } = useSavedUserSourceStore((param)=>{
        let { updateUserMeta  } = param;
        return {
            updateUserMeta
        };
    });
    const { logoutCommand  } = useMemo(()=>({
            logoutCommand: new LogoutCommand(apiService),
            isUseFooter: !isInWebview()
        }), [
        apiService
    ]);
    const onLogout = useCallback(async ()=>{
        if (user.providerId === "kakao") {
            postProcessForKakao();
        }
        logout();
        await logoutCommand.execute();
        redirect(redirectPath);
    }, [
        user,
        logoutCommand,
        postProcessForKakao
    ]);
    const handleLogout = ()=>{
        mixpanelEvent("GNB Button Clicked", {
            "button type": "mypage",
            "button name": "로그아웃"
        });
        updateUserMeta({
            mainSubject: null,
            job: null
        });
        closeSideMenu();
        onLogout();
    };
    return {
        handleLogout,
        isLoggedIn
    };
};
export default useLogout;
