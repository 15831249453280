import { useState } from "react";
export const useDropdownMenu = ()=>{
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event)=>{
        setAnchorEl(event.currentTarget);
    };
    const handleClose = ()=>{
        setAnchorEl(null);
    };
    return {
        handleOpen,
        handleClose,
        open,
        anchorEl
    };
};
