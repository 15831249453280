import { otherApiClient } from ".";
import { LibrarySearchType } from "./library";
export const getClassSourceTypeName = (sourceType)=>{
    switch(sourceType){
        case "workbook":
        case "textbook":
            return "교재";
        case "handout":
            return "자료";
        default:
            const label = Object.keys(LibrarySearchType).find((k)=>LibrarySearchType[k] === sourceType);
            return label;
    }
};
export const fetchClasses = async (param)=>{
    let { ...params } = param;
    const { data  } = await otherApiClient.market("/classes", {
        params
    });
    return data;
};
export const postClass = async (param)=>{
    let { ...params } = param;
    const { data  } = await otherApiClient.market.post("/classes", params);
    return data;
};
export const fetchClass = async (param)=>{
    let { id , ...params } = param;
    const { data  } = await otherApiClient.market("/classes/".concat(id), {
        params
    });
    return data;
};
export const patchClass = async (param)=>{
    let { id , ...params } = param;
    const { data  } = await otherApiClient.market.patch("/classes/".concat(id), params);
    return data;
};
export const deleteClass = async (param)=>{
    let { id  } = param;
    const { data  } = await otherApiClient.market.delete("/classes/".concat(id));
    return data;
};
export const fetchClassUseBooks = async (param)=>{
    let { id , ...params } = param;
    const { data  } = await otherApiClient.market("/classes/".concat(id, "/use-books"), {
        params
    });
    return data;
};
export const patchClassUseBooks = async (param)=>{
    let { id , ...params } = param;
    const { data  } = await otherApiClient.market.patch("/classes/".concat(id, "/use-books"), params);
    return data;
};
