/**
 * 로그아웃 명령
 * @param
 * @returns Promise<LogoutResponseDTO>
 */ export class LogoutCommand {
    execute() {
        const url = "/sessions";
        return this.apiService.fetch(url, {
            method: "DELETE"
        }).then((res)=>res.data);
    }
    constructor(apiService){
        this.apiService = apiService;
    }
}
