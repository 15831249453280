import { toQueryStringCamel } from "../utils/misc";
import { otherApiClient } from "./index";
export const getAdBanner = async (param)=>{
    let { position , subject , pageId  } = param;
    const { data  } = await otherApiClient.market("/adBanners/".concat(toQueryStringCamel({
        position,
        subject,
        pageId
    })));
    return data;
};
