import { otherApiClient } from "./index";
export const BrandCategorySortType = {
    NEW: "최신순",
    DATATYPE: "자료명순"
};
export const FilterKey = {
    RELATEDBOOKS: "relatedBooks",
    UNITS: "units",
    DATATYPES: "dataTypes",
    TICKETS: "tickets",
    SUBJECT: "subject"
};
export const getBrand = async (name, token)=>{
    const headers = {
        Authorization: token
    };
    const { data  } = await otherApiClient.market("/brands/".concat(name), {
        headers
    });
    return data;
};
export const postSubscribeBrand = async (param)=>{
    let { name , ...rest } = param;
    const { data  } = await otherApiClient.market.post("/brands/".concat(name, "/subscribe"), rest);
    return data;
};
export const getBrandCount = async (param)=>{
    let { name  } = param;
    const { data  } = await otherApiClient.market("/brands/".concat(name, "/search/count"));
    return data;
};
export const getBrandCategory = async (name)=>{
    const { data  } = await otherApiClient.market("/brands/".concat(name, "/categories"));
    return data;
};
export const getBrandCategoryId = async (param)=>{
    let { name , categoryId , ...rest } = param;
    const { data  } = await otherApiClient.market("/brands/".concat(name, "/categories/").concat(categoryId), {
        params: {
            ...rest
        }
    });
    return data;
};
export const getBrandSearch = async (param)=>{
    let { name , ...rest } = param;
    const { data  } = await otherApiClient.market("/brands/".concat(name, "/search"), {
        params: {
            ...rest
        }
    });
    return data;
};
export const getBrandSearchFilter = async (param)=>{
    let { name , ...rest } = param;
    const { data  } = await otherApiClient.market("/brands/".concat(name, "/search/filter"), {
        params: {
            ...rest
        }
    });
    return data;
};
