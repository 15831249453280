import { _ as _tagged_template_literal } from "@swc/helpers/_/_tagged_template_literal";
function _templateObject() {
    const data = _tagged_template_literal([
        "\n  flex: 1;\n  width: 100%;\n"
    ]);
    _templateObject = function() {
        return data;
    };
    return data;
}
import * as React from "react";
import styled from "styled-components";
export const View = styled.div.withConfig({
    componentId: "sc-ff41ff95-0"
})(_templateObject());
