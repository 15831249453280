import { RestfulApiService } from "./RestfulApiService";
const API_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT;
const apiService = new RestfulApiService({
    maxRetry: 3
});
apiService.setApiEndpoint({
    AUTH: [
        API_ENDPOINT
    ],
    ALL: [
        API_ENDPOINT
    ]
});
apiService.initialize();
export default apiService;
