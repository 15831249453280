import { create } from "zustand";
import { persist } from "zustand/middleware";
export const useMainPageStore = create((set, get)=>({
        subject: null,
        selectedBookList: [],
        userMeta: {
            job: null,
            mainSubject: null
        },
        modalType: "selectBooksModal",
        selectedBook: null,
        selectedDataType: null,
        setSubject: (subject)=>{
            //과목 변경시 선택교재 리스트, 선택교재 초기화
            set((state)=>{
                return {
                    subject,
                    selectedBookList: [],
                    selectedBook: null
                };
            });
        },
        setSelectedBookList: (selectedBook)=>{
            //교재 선택시 선택교재 리스트에 추가/제거
            set((state)=>{
                const findBook = state.selectedBookList.find((item)=>item.id === selectedBook.id);
                const newSelectedBookList = findBook ? state.selectedBookList.filter((item)=>item.id !== selectedBook.id) : [
                    ...state.selectedBookList,
                    selectedBook
                ];
                const newSelectedBook = newSelectedBookList.length > 0 ? newSelectedBookList[0] : null;
                return {
                    selectedBookList: newSelectedBookList,
                    selectedBook: newSelectedBook
                };
            });
        },
        setInitialSelectedBookList: (selectedBooks)=>{
            set((state)=>({
                    initialSelectedBookList: selectedBooks
                }));
        },
        setAllSelectedBookList: (selectedBookList)=>{
            set((state)=>{
                return {
                    selectedBookList,
                    selectedBook: selectedBookList.length > 0 ? selectedBookList[0] : null
                };
            });
        },
        setUserMeta: (param)=>{
            let { job , mainSubject  } = param;
            return set({
                userMeta: {
                    job,
                    mainSubject
                }
            });
        },
        setModalType: (modalType)=>set({
                modalType
            }),
        setSelectedBook: (selectedBook)=>{
            set((state)=>{
                return {
                    selectedBook,
                    selectedDataType: null
                };
            });
        },
        setSelectedDataType: (selectedDataType)=>set({
                selectedDataType
            })
    }));
const StorageKey = "user-selected-books";
export const useSavedUserSourceStore = create(persist((set, get)=>({
        savedUserSource: [],
        savedUserMeta: {
            job: null,
            mainSubject: null,
            schoolItems: []
        },
        setSavedUserSource: (userSource)=>{
            const index = get().savedUserSource.findIndex((item)=>item.subject === userSource.subject);
            let newSavedUserSource;
            if (index !== -1) {
                newSavedUserSource = [
                    ...get().savedUserSource
                ];
                newSavedUserSource[index] = userSource;
            } else {
                newSavedUserSource = [
                    ...get().savedUserSource,
                    userSource
                ];
            }
            set({
                savedUserSource: newSavedUserSource
            });
        },
        updateUserMeta: (userMeta)=>{
            set({
                savedUserMeta: userMeta
            });
        }
    }), {
    name: StorageKey
}));
