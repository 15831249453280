import { otherApiClient } from ".";
export function isLibraryGeneralFilter(value) {
    return value["id"] !== undefined;
}
export const LibraryFiltersMap = {
    brands: {
        label: "제작자",
        colSpan: 12,
        order: 3
    },
    dataTypes: {
        label: "자료유형",
        colSpan: 24,
        order: 4
    },
    units: {
        label: "단원",
        colSpan: 24,
        order: 2
    },
    publishers: {
        label: "출판사",
        colSpan: 24
    },
    curriculums: {
        label: "학년",
        colSpan: 24
    },
    genres: {
        label: "장르",
        colSpan: 12,
        order: 5
    },
    eras: {
        label: "시대",
        colSpan: 12,
        order: 6
    },
    useBooks: {
        label: "이용 교재",
        colSpan: 24
    },
    filterParsing: {
        label: "편집 가능",
        colSpan: 24
    },
    baseSources: {
        label: "교재",
        colSpan: 24,
        order: 1
    },
    packages: {
        label: "패키지",
        colSpan: 24,
        order: 7
    }
};
export const LibrarySearchType = {
    자료: "handout",
    교과서: "textbook",
    참고서: "workbook",
    문학: "literature",
    비문학: "non_literature"
};
export function isLibrarySearchType(value) {
    return !!Object.values(LibrarySearchType).find((v)=>v === value);
}
export const getLibrarySearchTypeName = (key)=>Object.keys(LibrarySearchType).find((k)=>LibrarySearchType[k] === key);
// export interface GetLibraryFiltersResponse extends IQueryResponse {
// 	filters: LibraryFilters
// }
export const fetchLibraryFilters = async (params)=>{
    const { data  } = await otherApiClient.market("/libraries/filters", {
        params: {
            ...params,
            searchType: params.searchType.replaceAll("_", "-")
        }
    });
    return data;
};
export const fetchLibraryMetadata = async (params)=>{
    const { data  } = await otherApiClient.market("/libraries/metadata", {
        params
    });
    return data;
};
export const LibraryFilterRequestMap = {
    brands: "brand",
    dataTypes: "dataType",
    units: "unit",
    publishers: "publisher",
    curriculums: "curriculum",
    genres: "genre",
    eras: "era",
    useBooks: "useBook",
    filterParsing: "parsing",
    baseSources: "baseSource",
    packages: "package"
};
export const fetchLibraries = async (params)=>{
    const { data  } = await otherApiClient.market("/libraries", {
        params: {
            ...params,
            searchType: params.searchType.replaceAll("_", "-")
        }
    });
    return data;
};
export const fetchLibraryLicense = async (param)=>{
    let { id , ...params } = param;
    const { data  } = await otherApiClient.market("/libraries/".concat(id, "/check-can-open"), {
        params
    });
    return data;
};
