import { useState } from "react";
export const useMobileFilterSideSheet = ()=>{
    const [mobileFilterSideSheet, setMobileFilterSideSheet] = useState({
        isOpen: false,
        value: ""
    });
    const handleMobileSideSheet = (isOpen, value)=>{
        setMobileFilterSideSheet({
            isOpen,
            value
        });
    };
    return {
        mobileFilterSideSheet,
        handleMobileSideSheet
    };
};
