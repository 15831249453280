const defaultSeoConfig = {
    title: "쏠북 | 내신자료 플랫폼 1위",
    description: "현직 학원 강사 7만명이 선택한 자료 - 교과서, 모의고사, 참고서 변형문제 최다 보유",
    openGraph: {
        url: 'https://solvook.com"',
        type: "website",
        siteName: "solvook",
        images: [
            {
                url: "https://solvook.com/static/images/meta-image.jpg",
                alt: "solvook"
            }
        ]
    },
    // 키워드는 권장사항이 아니지만, 기존에 설정한 키워드가 존재하기에 추가하였습니다.
    additionalMetaTags: [
        {
            name: "keywords",
            content: "라이센스, 저작권, 교재, 이용권, 수업, 콘텐츠"
        }
    ]
};
export default defaultSeoConfig;
