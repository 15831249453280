import { toQueryStringCamel } from "src/utils/misc";
import { otherApiClient } from ".";
export const userTypeWithKorean = {
    teacher: "선생님",
    student: "중/고등학생",
    parent: "학부모",
    principal: "원장님",
    university: "대학생",
    etc: "기타"
};
//교재 리스트 가져오기
export const getUserSourcesSelection = async (param)=>{
    let { publisher , curriculum , subject  } = param;
    const { data  } = await otherApiClient.market.get("/users/sources/selection/".concat(toQueryStringCamel({
        publisher,
        curriculum,
        subject
    })));
    return data;
};
//맞춤교재 등록
export const putUserSource = async (param)=>{
    let { items , subject  } = param;
    const { data  } = await otherApiClient.market.put("/users/sources", {
        subject,
        items
    });
    return data;
};
//유저 메타 정보 저장
export const patchUserMeta = async (params)=>{
    const { data  } = await otherApiClient.market.patch("/users/meta", {
        ...params
    });
    return data;
};
export const getUserMeta = async ()=>{
    const { data  } = await otherApiClient.market.get("/users/meta");
    return data;
};
export const putUserAddress = async (params)=>{
    const { data  } = await otherApiClient.market.put("/users/address", params);
    return data;
};
